import {select, fork, take, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import config from '../../config';

/**
 * Search contract partners
 */
const searchContractPartners = function* searchContractPartners({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    const {searchQueryParams} = payload;

    // avoid old data being rendered
    yield put({
        type: contractPartnerActionTypes.STORE_CONTRACT_PARTNER_SEARCH_RESULTS,
        payload: {contractPartnerSearchResults: [], contractPartnerSearchResultsCount: 0},
    });

    yield fork(fetchRequest,
        contractPartnerActionTypes.SEARCH_CONTRACT_PARTNERS_REQUEST,
        partnerManagementService.getContractPartners, {
            searchQueryParams,
            country: config.CONTRACT_PARTNERS_SEARCH_COUNTRY_CODE,
        });

    const searchContractPartnersResponseAction = yield take([
        contractPartnerActionTypes.SEARCH_CONTRACT_PARTNERS_REQUEST_SUCCEEDED,
        contractPartnerActionTypes.SEARCH_CONTRACT_PARTNERS_REQUEST_FAILED,
    ]);

    if (!searchContractPartnersResponseAction.error) {
        const {response} = searchContractPartnersResponseAction.payload;
        const {
            contractPartnerDTOs: contractPartnerSearchResults,
            totalCount: contractPartnerSearchResultsCount,
        } = response;

        yield put({
            type: contractPartnerActionTypes.STORE_CONTRACT_PARTNER_SEARCH_RESULTS,
            payload: {contractPartnerSearchResults, contractPartnerSearchResultsCount},
        });
    }
};

export default searchContractPartners;
