import {take, fork, select, put} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {EmergencyContact} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import modalIds from '../../modalIds';

const updateCPContactDataFlow = function* updateCPContactDataFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    while (true) {
        const {payload} = yield take(contractPartnerActionTypes.INITIATE_EDIT_CP_CONTACT_DATA_FLOW);
        const {contractPartnerId, contactDataId, isAdditional = false} = payload;

        yield* openModal(modalIds.CONTRACT_PARTNER_CONTACT_DATA, {
            contactDataId: `${contactDataId}`,
            ...(isAdditional && {isAdditional: `${!!isAdditional}`}),
        });

        const chosenModalOption = yield take([
            contractPartnerActionTypes.CONFIRM_EDIT_CP_CONTACT_DATA,
            contractPartnerActionTypes.DECLINE_EDIT_CP_CONTACT_DATA,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === contractPartnerActionTypes.CONFIRM_EDIT_CP_CONTACT_DATA
        ) {
            const {contactData} = chosenModalOption.payload;

            yield fork(
                fetchRequest,
                contractPartnerActionTypes.UPDATE_CP_CONTACT_DATA_REQUEST,
                partnerManagementService.updateCPContactData,
                {
                    contactDataDTO: EmergencyContact.objectToPatchDTO(contactData),
                    contactDataId,
                },
            );

            const responseAction = yield take([
                contractPartnerActionTypes.UPDATE_CP_CONTACT_DATA_REQUEST_SUCCEEDED,
                contractPartnerActionTypes.UPDATE_CP_CONTACT_DATA_REQUEST_FAILED,
            ]);

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {contactDataDTO} = response;

                yield put({
                    type: contractPartnerActionTypes.STORE_CP_CONTACT_DATA,
                    payload: {contactDataDTO, contractPartnerId},
                });
            }
        }

        yield* closeModal(modalIds.CONTRACT_PARTNER_CONTACT_DATA, {
            contactDataId: `${contactDataId}`,
            ...(isAdditional && {isAdditional: `${!!isAdditional}`}),
        });
    }
};

export default updateCPContactDataFlow;
