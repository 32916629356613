import React, {useState, useRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import debounce from 'lodash.debounce';
import {useTranslate} from '@computerrock/formation-i18n';
import {persistenceStates} from '@ace-de/eua-entity-types';
import {useStyles, Modal, AutosuggestField, Option, ButtonPrimary} from '@ace-de/ui-components';
import {Icon, closeIcon, searchIcon, locationIcon} from '@ace-de/ui-components/icons';
import {Form, InputField} from '@ace-de/ui-components/form';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import * as contractPartnerSelectors from '../contractPartnerSelectors';
import config from '../../config';

const CPOperatingUnitDataModal = props => {
    const {cx} = useStyles();
    const {hasBackdrop, contractPartner, searchOperatingUnitLocationGeolocation} = props;
    const {confirmOperatingUnitCreation, declineOperatingUnitCreation, unit} = props;
    const {confirmOperatingUnitUpdate, declineOperatingUnitUpdate} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('cp_operating_unit_data_modal');
    const [newOperatingUnitLocation, setNewOperatingUnitLocation] = useState(unit?.location || '');
    const currentOperatingUnitLocationAddress = useRef(unit?.location?.formattedAddress || '');
    const lastOperatingUnitLocationSearchQuery = useRef(unit?.location?.formattedAddress || '');
    const searchOperatingUnitLocationGeolocationDebounced = useMemo(() => {
        if (typeof searchOperatingUnitLocationGeolocation === 'function') {
            return debounce(
                searchOperatingUnitLocationGeolocation,
                config.ARCGIS_ADDRESS_SUGGEST_GEOLOCATION_DEBOUNCE_TIMER,
            );
        }
        return () => null;
    }, [searchOperatingUnitLocationGeolocation]);

    const handleOperatingUnitLocationSearchQueryChange = searchQueryString => {
        if (searchQueryString
            && searchQueryString.toLowerCase() !== currentOperatingUnitLocationAddress.current.toLowerCase()
            && searchQueryString.length >= config.MINIMUM_SEARCH_QUERY_LENGTH
        ) {
            searchOperatingUnitLocationGeolocationDebounced({
                searchQueryString,
                contractPartnerId: contractPartner.id,
            });
        }
        lastOperatingUnitLocationSearchQuery.current = searchQueryString || '';
    };

    const handleOperatingUnitLocationCandidateSelect = locationCandidate => {
        currentOperatingUnitLocationAddress.current = locationCandidate.formattedAddress;
        lastOperatingUnitLocationSearchQuery.current = locationCandidate.formattedAddress;
        setNewOperatingUnitLocation(locationCandidate);
    };

    const handleOnSubmit = formValues => {
        if (!newOperatingUnitLocation
            || !newOperatingUnitLocation?.latitude
            || !newOperatingUnitLocation?.longitude
        ) return;
        const operatingUnitData = {
            contractPartnerId: contractPartner.id,
            name: formValues.name,
            location: newOperatingUnitLocation,
        };

        if (unit) {
            confirmOperatingUnitUpdate({
                operatingUnitData,
            });
            return;
        }
        confirmOperatingUnitCreation({
            operatingUnitData,
        });
    };

    const {operatingUnitLocationSearchQuery, operatingUnitLocationCandidates} = contractPartner;

    return (
        <Modal
            title={unit
                ? translateModal('title.edit_operating_unit')
                : translateModal('title.create_operating_unit')}
            action={(
                <Icon icon={closeIcon} onClick={unit ? declineOperatingUnitUpdate : declineOperatingUnitCreation} />
            )}
            hasBackdrop={hasBackdrop}
        >
            <div>
                <Form
                    name="createCPOperatingUnitForm"
                    onSubmit={handleOnSubmit}
                    isDisabled={contractPartner.persistenceState === persistenceStates.PENDING}
                >
                    {formValues => {
                        const isButtonDisabled = !formValues.name || !newOperatingUnitLocation || (
                            newOperatingUnitLocation && lastOperatingUnitLocationSearchQuery.current !== newOperatingUnitLocation.formattedAddress // eslint-disable-line max-len
                        );
                        return (
                            <div>
                                <InputField
                                    name="name"
                                    value={unit?.name || ''}
                                    label={translateModal('input_label.name')}
                                    className={cx([
                                        'global!ace-u-full-width',
                                        'global!ace-u-margin--16-0',
                                    ])}
                                />
                                <AutosuggestField
                                    name="operatingUnitLocationSearchQuery"
                                    label={translateModal('search_field_label.address')}
                                    value={newOperatingUnitLocation?.formattedAddress || ''}
                                    onChange={handleOperatingUnitLocationSearchQueryChange}
                                    onOptionSelect={handleOperatingUnitLocationCandidateSelect}
                                    optionValueSelector={locationCandidate => {
                                        return locationCandidate.formattedAddress;
                                    }}
                                    className={cx([
                                        'global!ace-u-full-width',
                                        'global!ace-u-margin--16-0',
                                    ])}
                                    icon={searchIcon}
                                >
                                    {(formValues['operatingUnitLocationSearchQuery'] || '').length >= config.MINIMUM_SEARCH_QUERY_LENGTH
                                    && operatingUnitLocationSearchQuery === lastOperatingUnitLocationSearchQuery.current
                                        ? operatingUnitLocationCandidates
                                            .slice(0, config.ARCGIS_ADDRESS_GEOLOCATION_RESULTS_COUNT)
                                            .map((locationCandidate, index) => {
                                                return (
                                                    <Option
                                                        key={index}
                                                        name={`operating-unit-location-candidate-${index}`}
                                                        value={locationCandidate}
                                                    >
                                                        <Icon
                                                            icon={locationIcon}
                                                            className={cx('global!ace-u-margin--right-16')}
                                                        />
                                                        {locationCandidate.formattedAddress}
                                                    </Option>
                                                );
                                            }) : null}
                                </AutosuggestField>
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--justify-center',
                                    ])}
                                >
                                    <ButtonPrimary
                                        name="submitOperatingUnit"
                                        type="submit"
                                        className={cx('global!ace-u-margin--top-32')}
                                        isDisabled={isButtonDisabled}
                                    >
                                        {translateModal('button_label.save')}
                                    </ButtonPrimary>
                                </div>
                            </div>
                        );
                    }}
                </Form>
            </div>
        </Modal>
    );
};

CPOperatingUnitDataModal.propTypes = {
    declineOperatingUnitCreation: PropTypes.func.isRequired,
    confirmOperatingUnitCreation: PropTypes.func.isRequired,
    declineOperatingUnitUpdate: PropTypes.func.isRequired,
    confirmOperatingUnitUpdate: PropTypes.func.isRequired,
    searchOperatingUnitLocationGeolocation: PropTypes.func.isRequired,
    hasBackdrop: PropTypes.bool,
    contractPartner: PropTypes.object,
    unit: PropTypes.object,
};

CPOperatingUnitDataModal.defaultProps = {
    hasBackdrop: true,
    contractPartner: null,
    unit: null,
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();
    const operatingUnitSelector = contractPartnerSelectors.createCPOperatingUnitSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
        unit: operatingUnitSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    declineOperatingUnitCreation: () => dispatch({
        type: contractPartnerActionTypes.DECLINE_CREATE_CP_OPERATING_UNIT,
    }),
    confirmOperatingUnitCreation: payload => dispatch({
        type: contractPartnerActionTypes.CONFIRM_CREATE_CP_OPERATING_UNIT,
        payload,
    }),
    declineOperatingUnitUpdate: () => dispatch({
        type: contractPartnerActionTypes.DECLINE_UPDATE_CP_OPERATING_UNIT,
    }),
    confirmOperatingUnitUpdate: payload => dispatch({
        type: contractPartnerActionTypes.CONFIRM_UPDATE_CP_OPERATING_UNIT,
        payload,
    }),
    searchOperatingUnitLocationGeolocation: payload => dispatch({
        type: contractPartnerActionTypes.SEARCH_CP_OPERATING_UNIT_LOCATION_GEOLOCATION,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CPOperatingUnitDataModal);
