import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {HighlightCircle, Icon, closeIcon, InteractiveIcon, infoAlertIcon, Modal, useStyles} from '@ace-de/ui-components';
import * as applicationActionTypes from '../applicationActionTypes';

const ErrorMessageModal = props => {
    const {cx} = useStyles();
    const {hasBackdrop, closeModal, location} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('error_message_modal');
    const errorMessageType = location?.query?.errorType;

    const handleOnKeyDown = useCallback(event => {
        if (event.key === 'Escape') closeModal();
    }, [closeModal]);

    useEffect(() => {
        document.addEventListener('keydown', handleOnKeyDown, true);

        return () => {
            document.removeEventListener('keydown', handleOnKeyDown, true);
        };
    }, [handleOnKeyDown]);

    return (
        <Modal
            hasBackdrop={hasBackdrop}
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={closeModal}
                />
            )}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-full-width',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-flex--justify-content-center',
                ])}
            >
                <HighlightCircle
                    className={cx([
                        'ace-c-highlight-circle--medium',
                        'ace-c-highlight-circle--primary-highlight',
                    ])}
                >
                    <Icon
                        icon={infoAlertIcon}
                        className={cx('ace-c-icon--xxl', 'global!ace-c-icon--color-highlight')}
                    />
                </HighlightCircle>
                <h1 className={cx('global!ace-u-margin--24-0')}>
                    {translateModal('heading.error')}
                </h1>
                {errorMessageType && typeof errorMessageType === 'string' && (
                    <p className={cx(['global!ace-u-margin--bottom-24', 'global!ace-u-typography--align-center'])}>
                        {translateModal(`error_message.${snakeCase(errorMessageType)}`)}
                    </p>
                )}
            </div>
        </Modal>
    );
};

ErrorMessageModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,
    location: PropTypes.object,
};

ErrorMessageModal.defaultProps = {
    hasBackdrop: true,
    location: null,
};

const mapDispatchToProps = dispatch => ({
    closeModal: () => dispatch({
        type: applicationActionTypes.DECLINE_ERROR_MESSAGE_FLOW,
        payload: null,
    }),
});

export default connect(null, mapDispatchToProps)(ErrorMessageModal);
