import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {HighlightCircle, Modal, useStyles} from '@ace-de/ui-components';
import {closeIcon, Icon, infoAlertIcon, InteractiveIcon} from '@ace-de/ui-components/icons';
import * as qualityManagementFeedbackActionTypes from '../qualityManagementFeedbackActionTypes';

const QualityManagementFeedbackErrorModal = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('quality_management_feedback_error_modal');
    const {hasBackdrop, closeModal, location} = props;
    const errorType = location?.query.type;

    return (
        <Modal
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={closeModal}
                />
        )}
            hasBackdrop={hasBackdrop}
            contentClassName={cx('global!ace-u-modal-content-size--s')}
            hasColoredHeader={false}
            hasContentPadding={true}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-full-width',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-flex--justify-content-center',
                ])}
            >
                <HighlightCircle
                    className={cx([
                        'ace-c-highlight-circle--medium',
                        'ace-c-highlight-circle--primary-highlight',
                    ])}
                >
                    <Icon
                        icon={infoAlertIcon}
                        className={cx('ace-c-icon--xxl', 'global!ace-c-icon--color-highlight')}
                    />
                </HighlightCircle>
                <span
                    className={cx([
                        'global!ace-u-margin--24-0',
                        'global!ace-u-typography--variant-h3',
                        'global!ace-u-typography--align-center',
                    ])}
                >
                    {translateModal(`error_message.${errorType.toLowerCase()}`)}
                </span>
                <div />
            </div>
        </Modal>
    );
};

QualityManagementFeedbackErrorModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
};

QualityManagementFeedbackErrorModal.defaultProps = {
    hasBackdrop: false,
};

const mapDispatchToProps = dispatch => ({
    closeModal: payload => dispatch({
        type: qualityManagementFeedbackActionTypes.CLOSE_QUALITY_MANAGEMENT_FEEDBACK_ERROR_MODAL,
        payload,
    }),
});

export default connect(null, mapDispatchToProps)(QualityManagementFeedbackErrorModal);
