import {fork, put, select, take} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as priceManagementActionTypes from '../priceManagementActionTypes';

const fetchPricesByTimePeriod = function* fetchPricesByTimePeriod({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const pricingManagementService = serviceManager.loadService('pricingManagementService');
    const {pricePeriodId} = payload;

    yield fork(
        fetchRequest,
        priceManagementActionTypes.FETCH_PRICE_PERIOD_REQUEST,
        pricingManagementService.getPricePeriod,
        {pricePeriodId},
    );

    const responseAction = yield take([
        priceManagementActionTypes.FETCH_PRICE_PERIOD_REQUEST_SUCCEEDED,
        priceManagementActionTypes.FETCH_PRICE_PERIOD_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {pricePeriodDTO} = response;

        yield put({
            type: priceManagementActionTypes.STORE_PRICES_BY_TIME,
            payload: {pricePeriodDTO},
        });
    }
};

export default fetchPricesByTimePeriod;
