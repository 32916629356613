import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {Panel, useStyles} from '@ace-de/ui-components';
import {InteractiveIcon, editIcon} from '@ace-de/ui-components/icons';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {apsPriceTypes} from '@ace-de/eua-entity-types';
import getPrice from '../../utils/getPrice';
import * as priceManagementActionTypes from '../priceManagementActionTypes';

const PickupRatesPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, activeLocale} = useTranslate();
    const {pickupPrices, isEditingDisabled, initiateUpdatePickupRates} = props;
    const translatePanel = createTranslateShorthand('pickup_rates_panel');

    return (
        <Panel
            title={translatePanel('title.pickup_rates')}
            actions={!isEditingDisabled && (
                <InteractiveIcon
                    icon={editIcon}
                    onClick={() => initiateUpdatePickupRates({contentType: apsPriceTypes.PICKUP_PRICE})}
                />
            )}
            className={cx('ace-c-panel--full-bleed-content')}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell qaIdentPart="pickup-rates-from-km">
                            {translatePanel('table_header.from')}
                        </TableCell>
                        <TableCell qaIdentPart="pickup-rates-to-km">
                            {translatePanel('table_header.to')}
                        </TableCell>
                        <TableCell qaIdentPart="pickup-rates-price">
                            <div className={cx('global!ace-u-typography--align-right')}>
                                {translatePanel('table_header.price')}
                            </div>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {pickupPrices.length > 0 && pickupPrices.map((pickupPrice, idx) => {
                        const {distanceFrom, distanceTo, id, value} = pickupPrice;
                        return (
                            <TableRow
                                key={`${pickupPrice.id}-${idx}`}
                                qaIdentPart={id}
                            >
                                <TableCell
                                    qaIdentPart="pickup-price-distance-from"
                                    qaIdentPartPostfix={id}
                                >
                                    {distanceFrom || '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="pickup-price-distance-to"
                                    qaIdentPartPostfix={id}
                                >
                                    {distanceTo || '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="pickup-price-value"
                                    qaIdentPartPostfix={id}
                                >
                                    <div className={cx('global!ace-u-typography--align-right')}>
                                        {value ? getPrice(value, activeLocale) : '-'}
                                    </div>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Panel>
    );
};

PickupRatesPanel.propTypes = {
    pickupPrices: PropTypes.array.isRequired,
    isEditingDisabled: PropTypes.bool.isRequired,
    initiateUpdatePickupRates: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    return {
        pickupPrices: state.priceManagement.pickupPrices,
    };
};

const mapDispatchToProps = dispatch => ({
    initiateUpdatePickupRates: payload => dispatch({
        type: priceManagementActionTypes.INITIATE_UPDATE_PRICES_FLOW,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(PickupRatesPanel);
