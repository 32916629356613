import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, ContentBlock, ContentItem, DateField, calendarIcon, ButtonPrimary, Form} from '@ace-de/ui-components';
import RatesByTimePanel from './ui-elements/RatesByTimePanel';
import SurchargesPanel from './ui-elements/SurchargesPanel';
import FixedRatesPanel from './ui-elements/FixedRatesPanel';
import SimpleServiceRatesPanel from './ui-elements/SimpleServiceRatesPanel';
import PickupRates from './ui-elements/PickupRatesPanel';
import * as priceManagementActionTypes from './priceManagementActionTypes';

const checkOverlap = (validFrom, validUntil, timePeriods, isEditingValueFromDisabled) => {
    if (!validUntil || !validFrom || !timePeriods) return true;
    const momentFrom = moment(validFrom, 'YYYY-MM-DD');
    const momentUntil = moment(validUntil, 'YYYY-MM-DD');
    const now = moment().format('YYYY-MM-DD');

    if ((isEditingValueFromDisabled && momentUntil.isSameOrBefore(moment(now)))
        || (!isEditingValueFromDisabled && (momentFrom.isBefore(now) || momentUntil.isSameOrBefore(now)))) {
        return true;
    }

    if (momentUntil.isSameOrBefore(momentFrom)) {
        return true;
    }

    return timePeriods.some(timePeriod => {
        const periodFrom = moment(timePeriod.validFrom, 'YYYY-MM-DD');
        const periodUntil = moment(timePeriod.validUntil, 'YYYY-MM-DD');

        return (
            momentFrom.isBetween(periodFrom, periodUntil)
            || momentUntil.isBetween(periodFrom, periodUntil)
            || momentFrom.isSame(periodFrom)
            || momentFrom.isSame(periodUntil)
            || momentUntil.isSame(periodFrom)
            || momentUntil.isSame(periodUntil)
            || periodFrom.isBetween(momentFrom, momentUntil)
            || periodUntil.isBetween(momentFrom, momentUntil)
        );
    });
};

const PriceManagementScreen = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('price_management_screen');
    const {history, timePeriods, confirmPricePeriodUpdate} = props;
    const queryParams = new URLSearchParams(history.location.search);
    const currentPeriod = timePeriods.find(timePeriod => timePeriod.id === parseInt(queryParams.get('pricePeriodId'), 10));
    const otherPeriods = timePeriods.filter(timePeriod => (
        timePeriod.id !== currentPeriod?.id
    ));
    const [pricePeriodFormData, setPricePeriodFormData] = useState();
    const isEditingValidFromDisabled = moment(queryParams.get('validFrom')).isSameOrBefore(moment(), 'day');
    const isEditingValidUntilDisabled = moment(queryParams.get('validUntil')).isBefore(moment(), 'day');
    const [isPeriodDateUpdateDisabled, setIsPeriodDateUpdateDisabled] = useState(true);

    const handleOnFormChange = formValues => {
        setPricePeriodFormData(formValues);
        const hasOverlap = checkOverlap(
            formValues.validFrom,
            formValues.validUntil,
            otherPeriods,
            isEditingValidFromDisabled,
        );
        if (hasOverlap !== isPeriodDateUpdateDisabled) {
            setIsPeriodDateUpdateDisabled(hasOverlap);
        }
    };

    const handleOnSubmit = () => {
        if (Object.keys(pricePeriodFormData).length === 0) {
            return;
        }
        const pricePeriodData = {
            ...(pricePeriodFormData?.validFrom && !isEditingValidFromDisabled
                ? {validFrom: moment(pricePeriodFormData.validFrom).format('YYYY-MM-DD')}
                : {}
            ),
            ...(pricePeriodFormData?.validFrom && !isEditingValidUntilDisabled
                ? {validUntil: moment(pricePeriodFormData.validUntil).format('YYYY-MM-DD')}
                : {}),
            prices: [],
        };
        confirmPricePeriodUpdate({pricePeriodId: currentPeriod.id, pricePeriodData});
    };

    return (
        <div>
            <ContentBlock className={cx('global!ace-u-margin--top-8')}>
                <ContentItem className={cx('ace-c-content-item--span-4')}>
                    <RatesByTimePanel isEditingDisabled={isEditingValidFromDisabled || isEditingValidUntilDisabled} />
                    <SimpleServiceRatesPanel
                        isEditingDisabled={isEditingValidFromDisabled || isEditingValidUntilDisabled}
                    />
                </ContentItem>
                <ContentItem className={cx('ace-c-content-item--span-3')}>
                    <SurchargesPanel isEditingDisabled={isEditingValidFromDisabled || isEditingValidUntilDisabled} />
                    <FixedRatesPanel isEditingDisabled={isEditingValidFromDisabled || isEditingValidUntilDisabled} />
                </ContentItem>
                <ContentItem className={cx('ace-c-content-item--span-3')}>
                    <PickupRates isEditingDisabled={isEditingValidFromDisabled || isEditingValidUntilDisabled} />
                </ContentItem>
                <ContentItem className={cx('ace-c-content-item--span-2')}>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--direction-column',
                            'global!ace-u-flex--align-center',
                        ])}
                    >
                        <Form name="pricePeriodForm" onChange={handleOnFormChange} onSubmit={handleOnSubmit}>
                            <DateField
                                name="validFrom"
                                className={cx('global!ace-u-margin--bottom-24')}
                                label={translateScreen('date_field_label.valid_from')}
                                value={currentPeriod?.validFrom || ''}
                                icon={calendarIcon}
                                isDisabled={isEditingValidFromDisabled}
                            />
                            <DateField
                                name="validUntil"
                                className={cx('global!ace-u-margin--bottom-16')}
                                label={translateScreen('date_field_label.valid_until')}
                                value={currentPeriod?.validUntil || ''}
                                icon={calendarIcon}
                                isDisabled={isEditingValidUntilDisabled}
                            />
                            <ButtonPrimary
                                name="confirmChanges"
                                type="submit"
                                className={cx('global!ace-u-full-width')}
                                isDisabled={isPeriodDateUpdateDisabled || !pricePeriodFormData}
                            >
                                {translateScreen('button_label.save')}
                            </ButtonPrimary>
                        </Form>
                    </div>
                </ContentItem>
            </ContentBlock>
        </div>
    );
};

PriceManagementScreen.propTypes = {
    history: PropTypes.object.isRequired,
    confirmPricePeriodUpdate: PropTypes.func.isRequired,
    timePeriods: PropTypes.array,
};

PriceManagementScreen.defaultProps = {
    timePeriods: [],
};

const mapStateToProps = state => {
    return {
        timePeriods: state.priceManagement.timePeriods,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        confirmPricePeriodUpdate: payload => dispatch({
            type: priceManagementActionTypes.INITIATE_PRICE_PERIOD_UPDATE,
            payload,
        }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceManagementScreen);
