import {take, put, select, fork} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {OperatingUnit} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import modalIds from '../../modalIds';

const updateCPOperatingUnitFlow = function* updateCPOperatingUnitFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    while (true) {
        const {payload} = yield take(contractPartnerActionTypes.INITIATE_UPDATE_CP_OPERATING_UNIT_FLOW);
        const {contractPartnerId, unitId} = payload;

        yield* openModal(modalIds.CP_OPERATING_UNIT_DATA, {unitId: `${unitId}`});

        const chosenModalOption = yield take([
            contractPartnerActionTypes.CONFIRM_UPDATE_CP_OPERATING_UNIT,
            contractPartnerActionTypes.DECLINE_UPDATE_CP_OPERATING_UNIT,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === contractPartnerActionTypes.CONFIRM_UPDATE_CP_OPERATING_UNIT
        ) {
            const {operatingUnitData} = chosenModalOption.payload;

            yield fork(
                fetchRequest,
                contractPartnerActionTypes.UPDATE_CP_OPERATING_UNIT_REQUEST,
                partnerManagementService.updateCPOperatingUnit,
                {
                    operatingUnitPatchDTO: OperatingUnit.objectToPatchDTO(operatingUnitData),
                    unitId,
                },
            );

            const responseAction = yield take([
                contractPartnerActionTypes.UPDATE_CP_OPERATING_UNIT_REQUEST_SUCCEEDED,
                contractPartnerActionTypes.UPDATE_CP_OPERATING_UNIT_REQUEST_FAILED,
            ]);

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {operatingUnitDTO} = response;

                yield put({
                    type: contractPartnerActionTypes.STORE_CP_OPERATING_UNIT,
                    payload: {operatingUnitDTO, contractPartnerId, unitId},
                });
            }
        }

        yield* closeModal(modalIds.CP_OPERATING_UNIT_DATA, {unitId: `${unitId}`});
    }
};

export default updateCPOperatingUnitFlow;
