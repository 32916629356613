import React, {Fragment, useMemo, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {ArcGISMapWidget, createMarkerGraphic, createPolygonGraphic, markerTowingActivePNG, markerTowingPNG, markerVehiclePNG, markerWorkshopPNG, markerPersonPNG, useArcGISMap} from '@ace-de/eua-arcgis-map';
import {useStyles, Modal, Panel, Divider, Icon, closeIcon} from '@ace-de/ui-components';
import CPRankingItem from '../ui-elements/CPRankingItem';
import * as qualityManagementFeedbackActionTypes from '../qualityManagementFeedbackActionTypes';
import * as qualityManagementFeedbackSelectors from '../qualityManagementFeedbackSelectors';
import config from '../../config';
import modalIds from '../../modalIds';
import styles from './QMFeedbackCPRankingModal.module.scss';
import parseKMLPolygonData from '../parseKMLPolygonData';

const QMFeedbackCPRankingModal = props => {
    const {cx} = useStyles(props, styles);
    const {hasBackdrop, qmFeedback, activeContractPartnerId, setActiveContractPartnerId} = props;
    const {createTranslateShorthand, activeLocale} = useTranslate();
    const {closeModal} = useRouter();
    const arcGISMap = useArcGISMap('qm-feedback-cp-ranking-full-screen-map');
    const translateModal = createTranslateShorthand('qm_feedback_cp_ranking_modal');

    const [recommendedContractPartner, otherContractPartners] = useMemo(() => {
        if (!qmFeedback?.contractPartnerRecommendations?.length) return [null, []];
        const recommendedCP = {...(qmFeedback.contractPartnerRecommendations
            .find(contractPartner => contractPartner.rank === 1) || {})};
        if (recommendedCP.fixPricePolygon) {
            recommendedCP['parsedPolygonData'] = parseKMLPolygonData(recommendedCP.fixPricePolygon.trimStart());
        }
        const otherCPs = qmFeedback.contractPartnerRecommendations
            .filter(contractPartner => contractPartner.rank !== 1)
            .map(contractPartner => (contractPartner.fixPricePolygon
                ? {...contractPartner, 'parsedPolygonData': parseKMLPolygonData(contractPartner.fixPricePolygon.trimStart())}
                : contractPartner
            )).sort((cpA, cpB) => {
                return cpA.rank - cpB.rank;
            });
        return [recommendedCP, otherCPs];
    }, [qmFeedback?.contractPartnerRecommendations]);

    useEffect(() => {
        if (!arcGISMap) return;

        const markerGraphicData = [
            ...(recommendedContractPartner ? [recommendedContractPartner] : []),
            ...(otherContractPartners || []),
        ]
            .map(contractPartner => (contractPartner.location?.latitude && contractPartner.location?.longitude
                ? createMarkerGraphic({
                    id: `cpRankingItemPin-${contractPartner.id}`,
                    longitude: contractPartner.location.longitude,
                    latitude: contractPartner.location.latitude,
                    icon: contractPartner.id === activeContractPartnerId
                        ? markerTowingActivePNG
                        : markerTowingPNG,
                    isSmall: contractPartner.id !== activeContractPartnerId,
                    isSelectable: true,
                }) : null))
            .filter(graphicData => !!graphicData);

        const polygonGraphicData = [
            ...(recommendedContractPartner ? [recommendedContractPartner] : []),
            ...(otherContractPartners || []),
        ]
            .map(contractPartner => (contractPartner.parsedPolygonData
                ? createPolygonGraphic({
                    id: `cpRankingItemPolygon-${contractPartner.id}`,
                    geometry: contractPartner.parsedPolygonData,
                    isActive: contractPartner.id === activeContractPartnerId,
                }) : null))
            .filter(graphicData => !!graphicData);

        arcGISMap.setGraphics({
            graphics: [
                ...polygonGraphicData,
                ...(qmFeedback?.serviceLocation?.coordinates
                    && qmFeedback.serviceLocation.coordinates?.longitude
                    && qmFeedback.serviceLocation.coordinates?.latitude
                    ? [createMarkerGraphic({
                        id: 'serviceLocation',
                        longitude: qmFeedback.serviceLocation.coordinates.longitude,
                        latitude: qmFeedback.serviceLocation.coordinates.latitude,
                        icon: markerVehiclePNG,
                        isSmall: true,
                    })] : []),
                ...(qmFeedback?.towingDestination?.coordinates
                    && qmFeedback.towingDestination.coordinates?.longitude
                    && qmFeedback.towingDestination.coordinates?.latitude
                    ? [createMarkerGraphic({
                        id: 'towingDestination',
                        longitude: qmFeedback.towingDestination.coordinates.longitude,
                        latitude: qmFeedback.towingDestination.coordinates.latitude,
                        icon: markerWorkshopPNG,
                        isSmall: true,
                    })] : []),
                ...(qmFeedback?.memberLocation?.coordinates
                    && qmFeedback.memberLocation.coordinates?.longitude
                    && qmFeedback.memberLocation.coordinates?.latitude
                    ? [createMarkerGraphic({
                        id: 'memberLocation',
                        longitude: qmFeedback.memberLocation.coordinates.longitude,
                        latitude: qmFeedback.memberLocation.coordinates.latitude,
                        icon: markerPersonPNG,
                        isSmall: true,
                    })] : []),
                ...markerGraphicData,
            ],
        });
    }, [
        arcGISMap,
        activeContractPartnerId,
        recommendedContractPartner,
        otherContractPartners,
        qmFeedback?.serviceLocation?.coordinates,
        qmFeedback?.memberLocation?.coordinates,
        qmFeedback?.towingDestination?.coordinates,
    ]);

    // if no qmFeedback, don't render
    if (!qmFeedback) return null;

    return (
        <Modal
            hasBackdrop={hasBackdrop}
            className={cx([
                'global!ace-u-modal-content-size--xl',
                'global!ace-u-height--full',
                'global!ace-u-padding--top-0',
            ])}
            contentClassName={cx([
                'ace-c-modal__content--full-bleed',
                'global!ace-u-position--relative',
                'global!ace-u-height--full',
            ])}
        >
            <ArcGISMapWidget
                name="qm-feedback-cp-ranking-full-screen-map"
                tempArcGISMapId={config.TEMP_ARCGIS_MAP_ID}
                className={cx('ace-c-arcgis-map-widget--is-visible')}
                locale={activeLocale}
            />
            <div className={cx(['global!ace-u-grid', 'global!ace-u-max-height--full'])}>
                <Panel
                    title={translateModal('title.list_of_10')}
                    actions={(
                        <Icon
                            icon={closeIcon}
                            onClick={() => {
                                closeModal(modalIds.QM_FEEDBACK_CP_RANKING);
                                // set 'activeContractPartnerId' field to default
                                setActiveContractPartnerId({
                                    activeContractPartnerId: qmFeedback.contractPartnerId || null,
                                });
                            }}
                        />
                    )}
                    className={cx([
                        'ace-c-panel--full-bleed-content',
                        'global!ace-u-grid-column--span-3',
                        'global!ace-u-z-index--modal',
                        'global!ace-u-max-height--full',
                        'ace-c-qm-feedback-cp-ranking-modal__ranking-panel',
                    ])}
                >
                    <h4
                        className={cx([
                            'global!ace-u-typography--variant-body-bold',
                            'global!ace-u-typography--align-left',
                            'global!ace-u-margin--left-24',
                        ])}
                    >
                        {translateModal('heading.nearest_contract_partner')}
                    </h4>
                    {!!recommendedContractPartner && (
                    <Fragment>
                        <CPRankingItem
                            contractPartner={recommendedContractPartner}
                            isSelected={activeContractPartnerId === recommendedContractPartner?.id}
                            onClick={() => setActiveContractPartnerId({
                                activeContractPartnerId: recommendedContractPartner.id,
                            })}
                        />
                        <Divider />
                    </Fragment>
                    )}
                    <h4
                        className={cx([
                            'global!ace-u-typography--variant-body-bold',
                            'global!ace-u-typography--align-left',
                            'global!ace-u-margin--top-24',
                            'global!ace-u-margin--left-24',
                        ])}
                    >
                        {translateModal('heading.other_contract_partners')}
                    </h4>
                    {otherContractPartners.map((contractPartner, idx) => (
                        <Fragment
                            key={contractPartner.id}
                        >
                            <CPRankingItem
                                contractPartner={contractPartner}
                                isSelected={activeContractPartnerId === contractPartner.id}
                                onClick={() => setActiveContractPartnerId({
                                    activeContractPartnerId: contractPartner.id,
                                })}
                            />
                            {idx !== otherContractPartners.length - 1 && (
                                <Divider />
                            )}
                        </Fragment>
                    ))}
                </Panel>
            </div>
        </Modal>
    );
};

QMFeedbackCPRankingModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    qmFeedback: PropTypes.object,
    activeContractPartnerId: PropTypes.string,
    setActiveContractPartnerId: PropTypes.func.isRequired,
};

QMFeedbackCPRankingModal.defaultProps = {
    hasBackdrop: true,
    qmFeedback: null,
    activeContractPartnerId: null,
};

const mapStateToProps = (state, props) => {
    const qmFeedbackSelector = qualityManagementFeedbackSelectors.createQMFeedbackSelector();

    return {
        qmFeedback: qmFeedbackSelector(state, props),
        activeContractPartnerId: state.qualityManagementFeedbacks.activeContractPartnerId,
    };
};

const mapDispatchToProps = dispatch => ({
    setActiveContractPartnerId: payload => dispatch({
        type: qualityManagementFeedbackActionTypes.SET_ACTIVE_CONTRACT_PARTNER_ID,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(QMFeedbackCPRankingModal);
