import {take, select, put, fork} from 'redux-saga/effects';
import {ACEPartner, apmACEPartnerTypes} from '@ace-de/eua-entity-types';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import modalIds from '../../modalIds';

const contractPartnerAssignmentChannelsUpdateFlow = function* contractPartnerAssignmentChannelsUpdateFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    while (true) {
        yield take(contractPartnerActionTypes.INITIATE_CP_ASSIGNMENT_CHANNELS_UPDATE_FLOW);

        yield* openModal(modalIds.CP_ASSIGNMENT_CHANNELS_UPDATE);
        const chosenModalOption = yield take([
            contractPartnerActionTypes.CONFIRM_CP_ASSIGNMENT_CHANNELS_UPDATE,
            contractPartnerActionTypes.DECLINE_CP_ASSIGNMENT_CHANNELS_UPDATE,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === contractPartnerActionTypes.CONFIRM_CP_ASSIGNMENT_CHANNELS_UPDATE) {
            const {
                contractPartnerData,
                contractPartnerId,
            } = chosenModalOption.payload;

            yield fork(
                fetchRequest,
                contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_ASSIGNMENT_CHANNELS_REQUEST,
                partnerManagementService.updateACEPartner,
                {
                    acePartnerData: {
                        ...ACEPartner.objectToPatchDTO(contractPartnerData),
                        partnerType: apmACEPartnerTypes.CONTRACT_PARTNER,
                    },
                    acePartnerId: contractPartnerId,
                },
            );

            const responseAction = yield take([
                contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_ASSIGNMENT_CHANNELS_REQUEST_SUCCEEDED,
                contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_ASSIGNMENT_CHANNELS_REQUEST_FAILED,
            ]);

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {acePartnerDTO} = response;

                yield put({
                    type: contractPartnerActionTypes.STORE_CONTRACT_PARTNERS,
                    payload: {contractPartnerDTOs: [acePartnerDTO]},
                });
            }
        }
        yield* closeModal(modalIds.CP_ASSIGNMENT_CHANNELS_UPDATE);
    }
};

export default contractPartnerAssignmentChannelsUpdateFlow;
