import {put, take, fork, select} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as priceManagementActionTypes from '../priceManagementActionTypes';

const updatePricePeriodDates = function* updatePricePeriodDates({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const pricingManagementService = serviceManager.loadService('pricingManagementService');
    const {pricePeriodId, pricePeriodData} = payload;

    yield fork(
        fetchRequest,
        priceManagementActionTypes.UPDATE_PRICE_PERIOD_REQUEST,
        pricingManagementService.updatePricesByTimePeriod,
        {
            pricePeriodId,
            pricePeriodData,
        },
    );

    const responseAction = yield take([
        priceManagementActionTypes.UPDATE_PRICE_PERIOD_REQUEST_SUCCEEDED,
        priceManagementActionTypes.UPDATE_PRICE_PERIOD_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {pricePeriodDTO} = response;
        const {id, validFrom, validUntil} = pricePeriodDTO;

        yield put({
            type: priceManagementActionTypes.STORE_UPDATED_PERIOD,
            payload: {pricePeriodDTO: {id, validFrom, validUntil}},
        });
    }
};

export default updatePricePeriodDates;
