import React, {useState, useMemo, useEffect, useRef, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import debounce from 'lodash.debounce';
import {snakeCase} from 'change-case';
import {apmContractPartnerRegionTypes, apmContractPartnerContractStatusTypes} from '@ace-de/eua-entity-types';
import {ArcGISMapWidget, useArcGISMap, createMarkerGraphic, markerSearchPNG} from '@ace-de/eua-arcgis-map';
import {withRouter, resolveRoute, prepareSearchQueryParams} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, ContentBlock, ContentItem, Panel, ScrollableBlock, Option, InteractiveIcon, resetIcon} from '@ace-de/ui-components';
import {SelectField, Form, SearchBox, Checkbox} from '@ace-de/ui-components/form';
import ContractPartnerItem from './ui-elements/ContractPartnerItem';
import * as contractPartnerSelectors from '../contract-partners/contractPartnerSelectors';
import ContractPartnerReloadButton from './ui-elements/ContractPartnerReloadButton';
import config from '../config';
import routePaths from '../routePaths';
import * as contractPartnerActionTypes from '../contract-partners/contractPartnerActionTypes';
import contractPartnerInfoPopup from './contractPartnerInfoPopup';
import SidebarExpansionButton from './ui-elements/SidebarExpansionButton';
import styles from './DashboardScreen.module.scss';

const contractPartnerSearchTypes = {
    CONTRACT_PARTNER_ADDRESS: 'CONTRACT_PARTNER_ADDRESS',
    CONTRACT_PARTNER_NAME: 'CONTRACT_PARTNER_NAME',
    RANDOM_ADDRESS: 'RANDOM_ADDRESS',
};

const DashboardScreen = props => {
    const {cx} = useStyles(props, styles);
    const {contractPartnerRecommendations, isCPReloadButtonVisible, history, selectedContractPartner} = props;
    const {searchContractPartnerByAttribute, contractPartnerCandidates, randomLocationCandidates} = props;
    const {setContractPartnerCandidates, initiateContractPartnerSelectFlow, setRandomLocationCandidates} = props;
    const {changeCPServiceAreasLayerVisibilityState, searchRandomLocationGeolocation, hasPolygonsError} = props;
    const {isArcGISSessionValid} = props;
    const {createTranslateShorthand, translate} = useTranslate();
    const translateScreen = createTranslateShorthand('dashboard_screen');
    const arcGISMap = useArcGISMap('vpm-contract-partners');
    const [selectedContractPartnerId, setSelectedContractPartnerId] = useState('');
    const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
    const [queryParams, setQueryParams] = useState(new URLSearchParams(history.location.search));
    const [formData, setFormData] = useState({
        searchType: queryParams.get('searchType')
            ? queryParams.get('searchType') !== 'random_address'
                ? queryParams.get('searchType')
                : contractPartnerSearchTypes.RANDOM_ADDRESS
            : contractPartnerSearchTypes.RANDOM_ADDRESS,
        regions: queryParams.getAll('region').length > 0
            ? queryParams.getAll('region')
            : [],
        contractStatuses: queryParams.getAll('contractStatus').length > 0
            ? queryParams.getAll('contractStatus')
            : queryParams.has('lng') && queryParams.has('lat')
                ? []
                : [apmContractPartnerContractStatusTypes.ACTIVE],
    });
    const prevLocationKeyRef = useRef(history.location.key);
    const [contractPartnerSearchQuery, setContractPartnerSearchQuery] = useState(
        (new URLSearchParams(history.location.search))?.get('searchTerm') || '',
    );
    const searchContractPartnerByAttributeDebounced = useMemo(
        () => debounce(searchContractPartnerByAttribute, 250),
        [searchContractPartnerByAttribute],
    );
    const searchRandomLocationGeolocationDebounced = useMemo(
        () => debounce(searchRandomLocationGeolocation, 250),
        [searchRandomLocationGeolocation],
    );

    useEffect(() => {
        if (typeof history.location.search === 'string') {
            const queryParamsString = history.location.search || prepareSearchQueryParams({
                searchType: contractPartnerSearchTypes.RANDOM_ADDRESS,
                region: [],
                contractStatus: [apmContractPartnerContractStatusTypes.ACTIVE],
            });
            const newQueryParams = new URLSearchParams(queryParamsString);
            if (newQueryParams.toString() !== queryParams.toString()) {
                setQueryParams(newQueryParams);
                if (newQueryParams.get('searchTerm')) {
                    setContractPartnerSearchQuery(newQueryParams.get('searchTerm'));
                    return;
                }
                if (!history.location.search) {
                    setContractPartnerSearchQuery('');
                }
            }
        }
    }, [history.location.search, queryParams]);

    useEffect(() => {
        if (prevLocationKeyRef.current !== history.location.key && !history.location.search) {
            prevLocationKeyRef.current = history.location.key;
            setFormData({
                searchType: contractPartnerSearchTypes.RANDOM_ADDRESS,
                regions: [],
                contractStatuses: [apmContractPartnerContractStatusTypes.ACTIVE],
            });
        }
    }, [history.location.key, history.location.search]);

    useEffect(() => {
        if (!selectedContractPartner || selectedContractPartner['contractPartnerId'] === selectedContractPartnerId) return;

        setSelectedContractPartnerId(selectedContractPartner['contractPartnerId']);
    }, [selectedContractPartner, selectedContractPartnerId]);

    useEffect(() => {
        if (!arcGISMap) return;

        const locationId = queryParams.get('locationId');
        const [location] = randomLocationCandidates?.length
            ? randomLocationCandidates.filter(location => location['locationId'] === locationId) : [];

        if (locationId && !location) return;

        arcGISMap.setGraphics({
            graphics: [
                ...(location && location.latitude && location.longitude
                    ? [
                        createMarkerGraphic({
                            id: 'selectedLocation',
                            longitude: location.longitude,
                            latitude: location.latitude,
                            icon: markerSearchPNG,
                        }),
                    ] : []),
            ],
        });
    }, [arcGISMap, queryParams, randomLocationCandidates]);

    const handleContractPartnerSelect = newContractPartnerId => {
        if (!newContractPartnerId || selectedContractPartnerId === newContractPartnerId) return;

        setSelectedContractPartnerId(newContractPartnerId);

        const [newContractPartner] = contractPartnerRecommendations
            .filter(contractPartner => contractPartner['contractPartnerId'] === newContractPartnerId);

        if (newContractPartner) {
            initiateContractPartnerSelectFlow({
                contractPartner: newContractPartner,
            });
        }
    };

    const handleShowServiceAreas = value => {
        changeCPServiceAreasLayerVisibilityState({
            isVisible: !!value,
        });
    };

    const handleContractPartnerSearchQueryChange = searchQueryString => {
        if (!searchQueryString) {
            setContractPartnerSearchQuery('');
            // reset contract partner candidates & random location candidates
            setContractPartnerCandidates({
                contractPartnerCandidateDTOs: [],
            });
            setRandomLocationCandidates({
                randomLocationCandidateDTOs: [],
            });
            return;
        }

        if (searchQueryString.toLowerCase() !== contractPartnerSearchQuery.toLowerCase()
            && searchQueryString.length >= config.MINIMUM_SEARCH_QUERY_LENGTH) {
            if (formData.searchType.toUpperCase() === contractPartnerSearchTypes.RANDOM_ADDRESS) {
                searchRandomLocationGeolocationDebounced({
                    searchQueryString,
                    fieldToFilterBy: 'random_address',
                });
            } else {
                searchContractPartnerByAttributeDebounced({
                    searchQueryString,
                    fieldToFilterBy: formData.searchType.toUpperCase()
                    === contractPartnerSearchTypes.CONTRACT_PARTNER_ADDRESS
                        ? 'address' : 'name',
                });
            }
        }
        setContractPartnerSearchQuery(searchQueryString);
    };

    const formatQueryParams = formData => {
        if (!formData) return;
        const apiQueryParams = new URLSearchParams();
        Object.keys(formData).forEach(formField => {
            if (formData[formField] !== undefined && formData[formField] !== '') {
                if (formField !== 'regions' && formField !== 'contractStatuses') {
                    apiQueryParams.append(`${formField}`, formData[formField]);
                    return;
                }
                if (['regions', 'contractStatuses'].includes(formField) && Array.isArray(formData[formField])) {
                    formData[formField].forEach(fieldValue => {
                        const fieldName = formField === 'regions' ? 'region' : 'contractStatus';
                        apiQueryParams.append(`${fieldName}`, fieldValue);
                    });
                }
            }
        });
        return apiQueryParams;
    };

    const handleOnContractPartnerOptionSelect = contractPartnerId => {
        const [contractPartner] = contractPartnerCandidates
            .filter(contractPartner => contractPartner['contractPartnerId'] === contractPartnerId);
        if (contractPartner) {
            const apiQueryParams = formatQueryParams({
                searchType: formData.searchType === contractPartnerSearchTypes.CONTRACT_PARTNER_ADDRESS
                    ? 'address' : 'name',
                regions: formData.regions,
                contractStatuses: formData.contractStatuses,
                searchTerm: formData.searchType === contractPartnerSearchTypes.CONTRACT_PARTNER_ADDRESS
                    ? contractPartner['contractPartnerAddress'] : contractPartner['contractPartnerName'],
            });

            const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';

            history.replace(resolveRoute(
                routePaths.DASHBOARD, {}, {search: queryParamsString},
            ));
        }
    };

    const handleRegionChange = regions => {
        setFormData({
            ...formData,
            regions,
        });
    };

    const handleContractStatusChange = contractStatuses => {
        setFormData({
            ...formData,
            contractStatuses,
        });
    };

    const handleSearchTypeChange = searchType => {
        setFormData({
            ...formData,
            searchType,
        });
    };

    const handleOnLocationOptionSelect = locationId => {
        const [location] = randomLocationCandidates
            .filter(location => location['locationId'] === locationId);
        if (location) {
            const apiQueryParams = formatQueryParams({
                searchType: 'random_address',
                regions: formData.regions,
                contractStatuses: formData.contractStatuses,
                searchTerm: location['formattedAddress'],
                locationId: location['locationId'],
            });

            const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';

            history.replace(resolveRoute(
                routePaths.DASHBOARD, {}, {search: queryParamsString},
            ));
        }
    };

    const handleOnOptionSelect = optionId => {
        if (!optionId) return;
        if (formData.searchType === contractPartnerSearchTypes.RANDOM_ADDRESS) {
            handleOnLocationOptionSelect(optionId);
            return;
        }
        handleOnContractPartnerOptionSelect(optionId);
    };

    const handleOnSubmit = () => {
        if (contractPartnerSearchQuery.length < config.MINIMUM_CP_SEARCH_QUERY_LENGTH) return;

        // if an agent enters a random address and clicks on submit,
        // treat it the same as the agent clicks on the first suggestion
        const location = formData.searchType === contractPartnerSearchTypes.RANDOM_ADDRESS
            ? randomLocationCandidates[0] : null;

        const apiQueryParams = formatQueryParams({
            searchType: formData.searchType === contractPartnerSearchTypes.CONTRACT_PARTNER_ADDRESS
                ? 'address'
                : formData.searchType === contractPartnerSearchTypes.RANDOM_ADDRESS
                    ? 'random_address' : 'name',
            regions: formData.regions,
            contractStatuses: formData.contractStatuses,
            searchTerm: contractPartnerSearchQuery,
            ...(!!location?.locationId && {locationId: location['locationId']}),
        });

        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';

        history.replace(resolveRoute(
            routePaths.DASHBOARD, {}, {search: queryParamsString},
        ));
    };

    const handleOnPolygonReload = () => {
        // if an agent enters a random address and clicks on submit,
        // treat it the same as the agent clicks on the first suggestion
        const location = formData.searchType === contractPartnerSearchTypes.RANDOM_ADDRESS
            ? randomLocationCandidates[0] : null;

        const apiQueryParams = formatQueryParams({
            searchType: formData.searchType === contractPartnerSearchTypes.CONTRACT_PARTNER_ADDRESS
                ? 'address'
                : formData.searchType === contractPartnerSearchTypes.RANDOM_ADDRESS
                    ? 'random_address' : 'name',
            regions: formData.regions,
            contractStatuses: formData.contractStatuses,
            searchTerm: contractPartnerSearchQuery,
            ...(!!location?.locationId && {locationId: location['locationId']}),
        });

        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';

        history.replace(resolveRoute(
            routePaths.DASHBOARD, {}, {search: queryParamsString},
        ));
    };

    // on Enter, prevent form submission
    const onFormSubmit = event => {
        event.preventDefault();
    };

    // do not render if ArcGIS session is not valid
    if (!isArcGISSessionValid) return null;

    return (
        <ContentBlock
            className={cx([
                'global!ace-u-height--full',
                'global!ace-u-max-height--full',
                'global!ace-u-flex--align-stretch',
            ])}
        >
            <ArcGISMapWidget
                name="vpm-contract-partners"
                tempArcGISMapId={config.TEMP_ARCGIS_MAP_ID}
                center={config.STUTTGART_CENTER_COORDINATES}
                popup={contractPartnerInfoPopup}
                className={cx([
                    'ace-c-arcgis-map-widget--is-visible',
                    'ace-c-arcgis-map-widget--is-in-front',
                ])}
            />
            <ContentItem
                className={cx([
                    'ace-c-content-item--span-3',
                    'global!ace-u-height--full',
                    'global!ace-u-max-height--full',
                    'ace-c-dashboard-screen__left-sidebar',
                ], {
                    'ace-c-dashboard-screen__left-sidebar--shrinked': !isSidebarExpanded,
                })}
            >
                <Panel
                    className={cx([
                        'ace-c-panel--full-bleed-content',
                        'global!ace-u-height--full',
                        'global!ace-u-max-height--full',
                    ])}
                >
                    <ScrollableBlock isLabelDisabled={true} className={cx('ace-c-scrollable-block--full-bleed')}>
                        <Form
                            name="filterContractPartnerFeaturesForm"
                            onSubmit={onFormSubmit}
                        >
                            {formValues => {
                                return (
                                    <div className={cx(['global!ace-u-padding--left-32', 'global!ace-u-padding--right-32'])}>
                                        <SelectField
                                            name="searchType"
                                            value={queryParams.get('searchType')
                                                ? (queryParams.get('searchType') === 'name'
                                                    ? contractPartnerSearchTypes.CONTRACT_PARTNER_NAME
                                                    : queryParams.get('searchType') === 'address'
                                                        ? contractPartnerSearchTypes.CONTRACT_PARTNER_ADDRESS
                                                        : contractPartnerSearchTypes.RANDOM_ADDRESS)
                                                : formData.searchType}
                                            label={translateScreen('select_label.contract_partner_search')}
                                            className={cx([
                                                'global!ace-u-full-width',
                                                'global!ace-u-margin--bottom-48',
                                            ])}
                                            onChange={handleSearchTypeChange}
                                        >
                                            <Option
                                                name={contractPartnerSearchTypes.RANDOM_ADDRESS}
                                                value={contractPartnerSearchTypes.RANDOM_ADDRESS}
                                            >
                                                {translateScreen('select_option_label.random_address')}
                                            </Option>
                                            <Option
                                                name={contractPartnerSearchTypes.CONTRACT_PARTNER_ADDRESS}
                                                value={contractPartnerSearchTypes.CONTRACT_PARTNER_ADDRESS}
                                            >
                                                {translateScreen('select_option_label.address_known')}
                                            </Option>
                                            <Option
                                                name={contractPartnerSearchTypes.CONTRACT_PARTNER_NAME}
                                                value={contractPartnerSearchTypes.CONTRACT_PARTNER_NAME}
                                            >
                                                {translateScreen('select_option_label.name_known')}
                                            </Option>
                                        </SelectField>
                                        <div className={cx('global!ace-u-flex')}>
                                            <SearchBox
                                                name="contractPartnerSearchQuery"
                                                placeholder={translateScreen('input_placeholder.please_fill_in')}
                                                value={contractPartnerSearchQuery}
                                                onChange={handleContractPartnerSearchQueryChange}
                                                onSearchSubmit={handleOnSubmit}
                                                onOptionSelect={handleOnOptionSelect}
                                                isComposedIn={true}
                                                className={cx([
                                                    'global!ace-u-flex--grow-1',
                                                    'global!ace-u-margin--bottom-24',
                                                ])}
                                            >
                                                {contractPartnerCandidates.length > 0
                                                && formValues.searchType !== contractPartnerSearchTypes.RANDOM_ADDRESS
                                                    ? contractPartnerCandidates.map(contractPartner => (
                                                        <Option
                                                            key={contractPartner['contractPartnerId']}
                                                            value={contractPartner['contractPartnerId']}
                                                            name={contractPartner['contractPartnerId']}
                                                        >
                                                            {formValues.searchType === contractPartnerSearchTypes.CONTRACT_PARTNER_ADDRESS // eslint-disable-line max-len
                                                                ? contractPartner['contractPartnerAddress']
                                                                : contractPartner['contractPartnerName']}
                                                        </Option>
                                                    )) : (
                                                        randomLocationCandidates.length > 0 && formValues.searchType === contractPartnerSearchTypes.RANDOM_ADDRESS // eslint-disable-line max-len
                                                            ? randomLocationCandidates.map((location, idx) => (
                                                                <Option
                                                                    key={`${location.locationId}-${idx}`}
                                                                    value={location.locationId}
                                                                    name={`${location.locationId}-${idx}`}
                                                                >
                                                                    {location.formattedAddress}
                                                                </Option>
                                                            )) : (
                                                                <Option
                                                                    name="no-contract-partner-found"
                                                                    value={null}
                                                                >
                                                                    {translateScreen('search_box_results.no_results_message')}
                                                                </Option>
                                                            )
                                                    )}
                                            </SearchBox>
                                        </div>
                                        <div className={cx('global!ace-u-grid')}>
                                            <SelectField
                                                name="regions"
                                                value={formData.regions}
                                                label={translateScreen('select_label.region')}
                                                className={cx([
                                                    'global!ace-u-grid-column--span-6',
                                                    'global!ace-u-margin--bottom-48',
                                                ])}
                                                isMultipleChoice={true}
                                                onChange={handleRegionChange}
                                                placeholder={translate('global.region.all')}
                                            >
                                                {Object.keys(apmContractPartnerRegionTypes).map(region => (
                                                    <Option
                                                        key={region}
                                                        value={region}
                                                        name={region}
                                                    >
                                                        {translate(`global.region.${snakeCase(region)}`)}
                                                    </Option>
                                                ))}
                                            </SelectField>
                                            <SelectField
                                                name="contractStatuses"
                                                value={formData.contractStatuses}
                                                label={translateScreen('select_label.status')}
                                                className={cx([
                                                    'global!ace-u-grid-column--span-6',
                                                    'global!ace-u-margin--bottom-48',
                                                ])}
                                                isMultipleChoice={true}
                                                onChange={handleContractStatusChange}
                                                placeholder={translate('global.contract_status.all')}
                                            >
                                                {Object.keys(apmContractPartnerContractStatusTypes).map(status => (
                                                    <Option
                                                        key={status}
                                                        value={status}
                                                        name={status}
                                                    >
                                                        {translate(`global.contract_status.${snakeCase(status)}`)}
                                                    </Option>
                                                ))}
                                            </SelectField>
                                        </div>
                                        <Checkbox
                                            name="showContractPartnerArea"
                                            value={false}
                                            className={cx('global!ace-u-grid-column--span-12')}
                                            onChange={value => handleShowServiceAreas(value)}
                                            isDisabled={!!hasPolygonsError}
                                        >
                                            {translateScreen('checkbox_label.show_contract_partner_area')}
                                        </Checkbox>
                                        {!!hasPolygonsError && (
                                            <Fragment>
                                                <div
                                                    className={cx([
                                                        'global!ace-u-typography--color-warning',
                                                        'global!ace-u-typography--variant-caption',
                                                        'global!ace-u-margin--top-4',
                                                    ])}
                                                >
                                                    {translateScreen('error_message.polygons_are_not_loaded')}
                                                </div>
                                                <div
                                                    className={cx([
                                                        'global!ace-u-flex',
                                                        'global!ace-u-flex--align-center',
                                                        'global!ace-u-flex--justify-center',
                                                        'global!ace-u-margin--8-0',
                                                    ])}
                                                >
                                                    <InteractiveIcon
                                                        icon={resetIcon}
                                                        className={cx([
                                                            'ace-c-interactive-icon--reverse',
                                                            'ace-c-interactive-icon--highlight',
                                                        ])}
                                                        onClick={handleOnPolygonReload}
                                                    >
                                                        {translateScreen('button_label.reload_cp_polygons')}
                                                    </InteractiveIcon>
                                                </div>
                                            </Fragment>
                                        )}
                                    </div>
                                );
                            }}
                        </Form>
                        {contractPartnerRecommendations.length > 0
                            && contractPartnerRecommendations.map(contractPartner => {
                                return (
                                    <ContractPartnerItem
                                        key={contractPartner['contractPartnerId']}
                                        contractPartner={contractPartner}
                                        isSelected={contractPartner['contractPartnerId'] === selectedContractPartnerId}
                                        onSelect={handleContractPartnerSelect}
                                    />
                                );
                            })
                        }
                    </ScrollableBlock>
                </Panel>
            </ContentItem>
            <ContentItem
                className={cx([
                    'ace-c-content-item--span-9',
                    'global!ace-u-height--full',
                    'global!ace-u-max-height--full',
                ], {
                    'ace-c-content-item--span-12': !isSidebarExpanded,
                })}
            >
                <SidebarExpansionButton
                    isExpanded={isSidebarExpanded}
                    onClick={() => setIsSidebarExpanded(prevState => !prevState)}
                />
                {isCPReloadButtonVisible && (
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--justify-center',
                        ])}
                    >
                        <ContractPartnerReloadButton filtersData={formData} />
                    </div>
                )}
            </ContentItem>
        </ContentBlock>
    );
};

DashboardScreen.propTypes = {
    contractPartnerRecommendations: PropTypes.array,
    contractPartnerCandidates: PropTypes.array,
    randomLocationCandidates: PropTypes.array,
    isCPReloadButtonVisible: PropTypes.bool,
    searchContractPartnerByAttribute: PropTypes.func.isRequired,
    history: PropTypes.object,
    setContractPartnerCandidates: PropTypes.func.isRequired,
    setRandomLocationCandidates: PropTypes.func.isRequired,
    initiateContractPartnerSelectFlow: PropTypes.func.isRequired,
    selectedContractPartner: PropTypes.object,
    changeCPServiceAreasLayerVisibilityState: PropTypes.func.isRequired,
    searchRandomLocationGeolocation: PropTypes.func.isRequired,
    hasPolygonsError: PropTypes.bool,
    isArcGISSessionValid: PropTypes.bool,
};

DashboardScreen.defaultProps = {
    contractPartnerRecommendations: [],
    contractPartnerCandidates: [],
    randomLocationCandidates: [],
    isCPReloadButtonVisible: false,
    history: {},
    selectedContractPartner: null,
    hasPolygonsError: false,
    isArcGISSessionValid: false,
};
const mapStateToProps = state => {
    return {
        contractPartnerRecommendations: contractPartnerSelectors.getContractPartnerRecommendations(state),
        contractPartnerCandidates: contractPartnerSelectors.getContractPartnerCandidates(state),
        isCPReloadButtonVisible: state.contractPartners.isCPReloadButtonVisible,
        selectedContractPartner: state.contractPartners.selectedContractPartner,
        randomLocationCandidates: contractPartnerSelectors.getRandomLocationCandidates(state),
        hasPolygonsError: state.contractPartners.hasPolygonsError,
        isArcGISSessionValid: state.arcGISAuth.isSessionValid,
    };
};

const mapDispatchToProps = dispatch => ({
    searchContractPartnerByAttribute: payload => dispatch({
        type: contractPartnerActionTypes.SEARCH_CONTRACT_PARTNER_BY_ATTRIBUTE,
        payload,
    }),
    searchRandomLocationGeolocation: payload => dispatch({
        type: contractPartnerActionTypes.SEARCH_RANDOM_LOCATION_GEOLOCATION,
        payload,
    }),
    setContractPartnerCandidates: payload => dispatch({
        type: contractPartnerActionTypes.SET_CONTRACT_PARTNER_CANDIDATES,
        payload,
    }),
    setRandomLocationCandidates: payload => dispatch({
        type: contractPartnerActionTypes.SET_RANDOM_LOCATION_CANDIDATES,
        payload,
    }),
    initiateContractPartnerSelectFlow: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_CONTRACT_PARTNER_SELECT_FLOW,
        payload,
    }),
    changeCPServiceAreasLayerVisibilityState: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_CHANGE_CP_SERVICE_AREAS_LAYER_VISIBILITY_STATE,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardScreen));
