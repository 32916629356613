import {produce, current} from 'immer';
import {AffiliatePartner, ACEPartner, TemporaryRestriction, QualityManagementFeedback, Location, OperatingUnit, EmergencyContact, LeistContact, AccountParty, AccountManager, persistenceStates} from '@ace-de/eua-entity-types'; // eslint-disable-line max-len
import * as contractPartnerActionTypes from './contractPartnerActionTypes';
import createNewServiceCase from './createNewServiceCase';

const initialState = {
    contractPartners: {},
    contractPartnerRecommendations: [],
    contractPartnerCandidates: [],
    isCPReloadButtonVisible: false,
    isCPReloadButtonDisabled: false,
    contractPartnerSearchResults: [],
    contractPartnerSearchResultsCount: 0,
    selectedContractPartner: null,
    selectedContractPartnerOperationArea: null,
    contractPartnerRelations: [],
    randomLocationCandidates: [],
    services: [],
    commissioners: {},
    serviceCaseSearchResults: [],
    serviceCaseSearchResultsCount: 0,
    cpQMFeedbacksSearchResults: [],
    cpQMFeedbacksSearchCount: 0,
    affiliatePartners: [],
    contractPartnerLocationRecommendations: [],
    areContractPartnerBCDetailsLoading: false,
    contractPartnerBCDetails: {},
    isContractPartnerBCDetailsError: false,
    isContactSearchError: false,
    isContactSearchPerformed: false,
    contactSearchResults: [],
    contactSearchResultsCount: 0,
    contacts: [],
    contactsSearchPersistenceState: persistenceStates.READY,
    accountParties: [],
    hasPolygonsError: false,
    positiveQMFeedbacksCount: 0,
    negativeQMFeedbacksCount: 0,
    accountManagers: {},
};

const contractPartnerReducer = produce((draft, action) => {
    switch (action.type) {
        case contractPartnerActionTypes.STORE_CONTRACT_PARTNER_SEARCH_RESULTS: {
            const {contractPartnerSearchResults, contractPartnerSearchResultsCount} = action.payload;
            draft.contractPartnerSearchResults = contractPartnerSearchResults.map(contractPartnerDTO => {
                return new ACEPartner().fromDTO(contractPartnerDTO);
            });
            draft.contractPartnerSearchResultsCount = typeof contractPartnerSearchResultsCount === 'number'
                ? contractPartnerSearchResultsCount : 0;
            break;
        }

        case contractPartnerActionTypes.STORE_CONTRACT_PARTNERS: {
            const {contractPartnerDTOs} = action.payload;
            contractPartnerDTOs.forEach(contractPartnerDTO => {
                const contractPartner = draft.contractPartners[contractPartnerDTO.id];
                if (!contractPartner) {
                    const newContractPartner = new ACEPartner().fromDTO(contractPartnerDTO);
                    draft.contractPartners[newContractPartner.id] = newContractPartner;
                    return;
                }
                draft.contractPartners[contractPartnerDTO.id] = contractPartner.fromDTO(contractPartnerDTO);
            });
            break;
        }

        case contractPartnerActionTypes.SET_CONTRACT_PARTNER_RECOMMENDATIONS: {
            const {recommendedContractPartnerDTOs} = action.payload;
            draft.contractPartnerRecommendations = recommendedContractPartnerDTOs;
            break;
        }

        case contractPartnerActionTypes.SET_CONTRACT_PARTNER_CANDIDATES: {
            const {contractPartnerCandidateDTOs} = action.payload;
            draft.contractPartnerCandidates = contractPartnerCandidateDTOs;
            break;
        }

        case contractPartnerActionTypes.SET_RANDOM_LOCATION_CANDIDATES: {
            const {randomLocationCandidateDTOs} = action.payload;
            draft.randomLocationCandidates = randomLocationCandidateDTOs;
            break;
        }

        case contractPartnerActionTypes.SET_CONTRACT_PARTNERS_MAP_RELOAD_BUTTON_VISIBILITY: {
            const {isVisible} = action.payload;
            draft.isCPReloadButtonVisible = isVisible;
            break;
        }

        case contractPartnerActionTypes.SET_CONTRACT_PARTNERS_MAP_RELOAD_BUTTON_STATE: {
            const {isDisabled} = action.payload;
            draft.isCPReloadButtonDisabled = isDisabled;
            break;
        }

        case contractPartnerActionTypes.STORE_CONTRACT_PARTNER_RESTRICTIONS: {
            const {contractPartnerId, contractPartnerRestrictionDTOs} = action.payload;
            let draftContractPartner = current(draft.contractPartners[contractPartnerId]);

            if (draftContractPartner) {
                contractPartnerRestrictionDTOs.forEach(contractPartnerRestrictionDTO => {
                    const newContractPartnerRestrictions = new TemporaryRestriction().fromDTO(
                        contractPartnerRestrictionDTO,
                    );
                    draftContractPartner = draftContractPartner
                        .setTemporaryRestriction(newContractPartnerRestrictions.id, newContractPartnerRestrictions);
                });
                draft.contractPartners[contractPartnerId] = draftContractPartner;
            }
            break;
        }

        case contractPartnerActionTypes.SET_SELECTED_CONTRACT_PARTNER: {
            const {contractPartner} = action.payload;
            draft.selectedContractPartner = contractPartner;
            break;
        }

        case contractPartnerActionTypes.SET_SELECTED_CONTRACT_PARTNER_OPERATION_AREA: {
            const {selectedContractPartnerOperationArea} = action.payload;
            draft.selectedContractPartnerOperationArea = selectedContractPartnerOperationArea;
            break;
        }

        case contractPartnerActionTypes.STORE_CONTRACT_PARTNER_RELATIONS: {
            const {contractPartnerRelationsDTOs = []} = action.payload;
            draft.contractPartnerRelations = contractPartnerRelationsDTOs.map(affiliatePartnerDTO => {
                return new AffiliatePartner().fromDTO(affiliatePartnerDTO);
            });
            break;
        }

        case contractPartnerActionTypes.STORE_ACE_COMMISSIONERS: {
            const {commissionerDTOs} = action.payload;
            commissionerDTOs.forEach(commissionerDTO => {
                const commissioner = draft.commissioners[commissionerDTO.id];
                if (!commissioner) {
                    const newCommissioner = new ACEPartner().fromDTO(commissionerDTO);
                    draft.commissioners[newCommissioner.id] = newCommissioner;
                    return;
                }
                draft.commissioners[commissionerDTO.id] = commissioner.fromDTO(commissionerDTO);
            });
            break;
        }

        case contractPartnerActionTypes.STORE_SERVICE_CASE_SEARCH_RESULTS: {
            const {serviceCaseSearchResults, serviceCaseSearchResultsCount} = action.payload;
            draft.serviceCaseSearchResults = serviceCaseSearchResults.map(serviceCaseDTO => {
                return createNewServiceCase(serviceCaseDTO.caseType).fromDTO(serviceCaseDTO);
            });
            draft.serviceCaseSearchResultsCount = serviceCaseSearchResultsCount;
            break;
        }

        case contractPartnerActionTypes.STORE_CP_QUALITY_MANAGEMENT_FEEDBACKS_SEARCH_RESULTS: {
            const {qualityManagementFeedbacksSearchResults, qualityManagementFeedbacksSearchCount} = action.payload;
            const {positiveQMFeedbacksCount, negativeQMFeedbacksCount} = action.payload;
            draft.cpQMFeedbacksSearchResults = qualityManagementFeedbacksSearchResults.map(feedback => (
                new QualityManagementFeedback().fromDTO(feedback)
            ));
            draft.cpQMFeedbacksSearchCount = typeof qualityManagementFeedbacksSearchCount === 'number'
                ? qualityManagementFeedbacksSearchCount : 0;
            draft.positiveQMFeedbacksCount = typeof positiveQMFeedbacksCount === 'number'
                ? positiveQMFeedbacksCount : 0;
            draft.negativeQMFeedbacksCount = typeof negativeQMFeedbacksCount === 'number'
                ? negativeQMFeedbacksCount : 0;
            break;
        }

        case contractPartnerActionTypes.REMOVE_CP_OPERATING_UNIT: {
            const {unitId, contractPartnerId} = action.payload;
            const contractPartner = draft.contractPartners[contractPartnerId];

            const operatingUnitIndex = contractPartner.operatingUnits?.findIndex(unit => unit.id === unitId);
            if (operatingUnitIndex >= 0 && contractPartner.operatingUnits[operatingUnitIndex]) {
                contractPartner.operatingUnits[operatingUnitIndex].deleted = true;
            }
            break;
        }

        case contractPartnerActionTypes.SET_CP_OPERATING_UNIT_LOCATION_CANDIDATES: {
            const {arcGISLocationCandidateDTOs, contractPartnerId, searchQueryString} = action.payload;
            const contractPartner = draft.contractPartners[contractPartnerId];
            contractPartner[`operatingUnitLocationSearchQuery`] = searchQueryString;
            contractPartner[`operatingUnitLocationCandidates`] = arcGISLocationCandidateDTOs
                .map(arcGISLocationCandidateDTO => {
                    return new Location().fromDTO(arcGISLocationCandidateDTO);
                });
            break;
        }

        case contractPartnerActionTypes.STORE_CP_OPERATING_UNIT: {
            const {contractPartnerId, operatingUnitDTO} = action.payload;
            const contractPartner = draft.contractPartners[contractPartnerId];
            if (!contractPartner || !operatingUnitDTO) return;
            const index = contractPartner.operatingUnits.findIndex(unit => unit.id === operatingUnitDTO.id);
            if (index >= 0) {
                contractPartner.operatingUnits[index] = new OperatingUnit().fromDTO(operatingUnitDTO);
                break;
            }
            contractPartner.operatingUnits.push(new OperatingUnit().fromDTO(operatingUnitDTO));
            break;
        }

        case contractPartnerActionTypes.STORE_CP_CONTACT_DATA: {
            const {contractPartnerId, contactDataDTO} = action.payload;
            const contractPartner = draft.contractPartners[contractPartnerId];
            if (!contractPartner || !contactDataDTO) return;
            const index = contractPartner.emergencyContacts.findIndex(data => data.id === contactDataDTO.id);
            if (index >= 0) {
                contractPartner.emergencyContacts[index] = new EmergencyContact().fromDTO(contactDataDTO);
                break;
            }
            contractPartner.emergencyContacts.push(new EmergencyContact().fromDTO(contactDataDTO));
            break;
        }

        case contractPartnerActionTypes.REMOVE_CP_CONTACT_DATA: {
            const {contactDataId, contractPartnerId} = action.payload;
            const contractPartner = draft.contractPartners[contractPartnerId];
            const contactDataIndex = contractPartner.emergencyContacts?.findIndex(contact => {
                return contact.id === contactDataId;
            });
            if (contactDataIndex >= 0 && contractPartner.emergencyContacts[contactDataIndex]) {
                contractPartner.emergencyContacts[contactDataIndex].deleted = true;
            }
            break;
        }

        case contractPartnerActionTypes.SET_CP_LOCATION_RECOMMENDATIONS: {
            const {arcGISLocationCandidateDTOs} = action.payload;
            draft.contractPartnerLocationRecommendations = arcGISLocationCandidateDTOs;
            break;
        }

        case contractPartnerActionTypes.STORE_AFFILIATE_PARTNERS: {
            const affiliatePartners = action.payload;
            draft.affiliatePartners = affiliatePartners;
            break;
        }

        case contractPartnerActionTypes.SET_ARE_CONTRACT_PARTNER_BC_DETAILS_LOADING: {
            const {areContractPartnerBCDetailsLoading} = action.payload;
            draft.areContractPartnerBCDetailsLoading = areContractPartnerBCDetailsLoading;
            break;
        }

        case contractPartnerActionTypes.STORE_CONTRACT_PARTNER_BC_DETAILS: {
            const {contractPartnerBCDetails, bankAccount} = action.payload;
            draft.contractPartnerBCDetails = new LeistContact().fromDTO(contractPartnerBCDetails);
            draft.contractPartnerBCDetails.bankAccount = bankAccount;
            break;
        }

        case contractPartnerActionTypes.SET_IS_CONTRACT_PARTNER_BC_DETAILS_ERROR: {
            const {isContractPartnerBCDetailsError} = action.payload;
            draft.isContractPartnerBCDetailsError = isContractPartnerBCDetailsError;
            break;
        }

        case contractPartnerActionTypes.SEARCH_BC_CONTACTS: {
            draft.isContactSearchPerformed = true;
            break;
        }

        case contractPartnerActionTypes.STORE_BC_CONTACTS_SEARCH_RESULTS: {
            const {contactDTOs, totalCount} = action.payload;
            draft.contactSearchResults = contactDTOs.map(contactDTO => (
                new LeistContact().fromDTO(contactDTO)
            ));
            draft.contactSearchResultsCount = typeof totalCount === 'number' ? totalCount : 0;
            break;
        }

        case contractPartnerActionTypes.STORE_BC_CONTACTS_SEARCH_ERROR: {
            const {isContactSearchError} = action.payload;
            draft.isContactSearchError = isContactSearchError;
            break;
        }

        case contractPartnerActionTypes.DECLINE_BC_CONTACT_MODAL_FLOW: {
            draft.contactSearchResultsCount = 0;
            draft.contactSearchResults = [];
            draft.isContactSearchPerformed = false;
            break;
        }

        case contractPartnerActionTypes.STORE_CONTACTS: {
            const {contactDTOs, accountPartyDTOs} = action.payload;
            if (contactDTOs) {
                draft.contacts = contactDTOs.map(contactDTO => (
                    new LeistContact().fromDTO(contactDTO)
                ));
            }

            if (accountPartyDTOs) {
                draft.accountParties = accountPartyDTOs.map(accountPartyDTO => (
                    new AccountParty().fromDTO(accountPartyDTO)
                ));
            }
            break;
        }

        case contractPartnerActionTypes.SET_CONTRACT_PARTNERS_MAP_POLYGONS_ERROR: {
            const {hasPolygonsError} = action.payload;
            draft.hasPolygonsError = !!hasPolygonsError;
            break;
        }

        case contractPartnerActionTypes.STORE_HAS_ELLA_ACCOUNT: {
            const {hasELLAAccount, contractPartnerId, affiliatedPartnerELLAActive} = action.payload;
            if (draft.contractPartners[contractPartnerId]) {
                draft.contractPartners[contractPartnerId].affiliatedPartner
                    ? draft.contractPartners[contractPartnerId].affiliatedPartner = {
                        ...draft.contractPartners[contractPartnerId].affiliatedPartner,
                        ellaActive: affiliatedPartnerELLAActive,
                        hasELLAAccount,
                    }
                    : draft.contractPartners[contractPartnerId].hasELLAAccount = hasELLAAccount;
            }
            break;
        }

        case contractPartnerActionTypes.SET_CONTACTS_SEARCH_PERSISTENCE_STATE: {
            const {persistenceState} = action.payload;
            draft.contactsSearchPersistenceState = persistenceState;
            break;
        }

        case contractPartnerActionTypes.STORE_CONTRACT_PARTNER_ELO_PATH: {
            const {contractPartnerId, eloPath} = action.payload;
            const contractPartner = draft.contractPartners[contractPartnerId];
            if (contractPartner) {
                contractPartner['eloPath'] = eloPath;
            }
            break;
        }

        case contractPartnerActionTypes.STORE_CONTRACT_PARTNER_ACCOUNT_MANAGERS: {
            const {accountManagerDTOs = []} = action.payload;
            accountManagerDTOs.forEach(accountManagerDTO => {
                const accountManager = draft.accountManagers[accountManagerDTO.regionId];
                if (!accountManager) {
                    const newAccountManager = new AccountManager().fromDTO(accountManagerDTO);
                    draft.accountManagers[newAccountManager.regionId] = newAccountManager;
                    return;
                }
                draft.accountManagers[accountManagerDTO.regionId] = accountManager.fromDTO(accountManagerDTO);
            });
            break;
        }

        default: // no-op
    }
}, initialState);
export default contractPartnerReducer;
