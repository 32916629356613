import {take, select, fork, put} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {ACEPartner, apmACEPartnerTypes, deumUserRoleTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import modalIds from '../../modalIds';

const initiateCPCreateELLAAccountFlow = function* initiateCPCreateELLAAccountFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    const driverELLAUserManagementService = serviceManager.loadService('driverELLAUserManagementService');

    while (true) {
        const {payload} = yield take(contractPartnerActionTypes.INITIATE_CP_CREATE_ELLA_ACCOUNT_FLOW);
        const {contractPartnerId, contractPartnerName, affiliatePartnersIds} = payload;

        yield* openModal(modalIds.CP_CREATE_ELLA_ACCOUNT);

        const chosenModalOption = yield take([
            contractPartnerActionTypes.CONFIRM_CREATE_CP_ELLA_ACCOUNT,
            contractPartnerActionTypes.DECLINE_CREATE_CP_ELLA_ACCOUNT,
        ]);

        if (chosenModalOption && chosenModalOption.type === contractPartnerActionTypes.CONFIRM_CREATE_CP_ELLA_ACCOUNT) {
            // create new group on the keyCloak for the current CP
            yield fork(
                fetchRequest,
                contractPartnerActionTypes.CREATE_CP_USERS_GROUP_REQUEST,
                driverELLAUserManagementService.createUsersGroup,
                {
                    contractPartnerId,
                    contractPartnerName,
                    affiliatePartnersIds,
                },
            );
            const createGroupResponseAction = yield take([
                contractPartnerActionTypes.CREATE_CP_USERS_GROUP_REQUEST_SUCCEEDED,
                contractPartnerActionTypes.CREATE_CP_USERS_GROUP_REQUEST_FAILED,
            ]);

            if (createGroupResponseAction.error) {
                // TODO: handle errors
                yield* closeModal(modalIds.CP_CREATE_ELLA_ACCOUNT);
                continue;
            }

            const {ellaAccountData} = chosenModalOption.payload;
            const {name, email, password} = ellaAccountData;
            const [firstName = null, lastName = null] = name.split(' ');

            yield fork(
                fetchRequest,
                contractPartnerActionTypes.CREATE_ELLA_USER_REQUEST,
                driverELLAUserManagementService.createUser,
                {
                    firstName,
                    lastName,
                    email,
                    password,
                    isTempPassword: true,
                    userRoles: [
                        deumUserRoleTypes.ADMINISTRATOR,
                        deumUserRoleTypes.ACCOUNTANT,
                    ],
                    contractPartnersIds: [contractPartnerId, ...(affiliatePartnersIds || [])],
                },
            );
            const createELLAUserResponseAction = yield take([
                contractPartnerActionTypes.CREATE_ELLA_USER_REQUEST_SUCCEEDED,
                contractPartnerActionTypes.CREATE_ELLA_USER_REQUEST_FAILED,
            ]);

            if (createELLAUserResponseAction.error) {
                // TODO: handle errors
                yield* closeModal(modalIds.CP_CREATE_ELLA_ACCOUNT);
                continue;
            }

            // if the ELLA account is created, update the CP's 'ellaActive' flag
            yield fork(
                fetchRequest,
                contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_ELLA_ACTIVE_REQUEST,
                partnerManagementService.updateACEPartner,
                {
                    acePartnerData: {
                        ...ACEPartner.objectToPatchDTO({ellaActive: true}),
                        partnerType: apmACEPartnerTypes.CONTRACT_PARTNER,
                    },
                    acePartnerId: contractPartnerId,
                },
            );
            const responseAction = yield take([
                contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_ELLA_ACTIVE_REQUEST_SUCCEEDED,
                contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_ELLA_ACTIVE_REQUEST_FAILED,
            ]);

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {acePartnerDTO} = response;

                yield put({
                    type: contractPartnerActionTypes.STORE_CONTRACT_PARTNERS,
                    payload: {contractPartnerDTOs: [acePartnerDTO]},
                });
            }
        }

        yield* closeModal(modalIds.CP_CREATE_ELLA_ACCOUNT);
    }
};

export default initiateCPCreateELLAAccountFlow;
