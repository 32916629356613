import {fork, put, select, take} from 'redux-saga/effects';
import {apmACEPartnerTypes, apmContractPartnerRegionTypes, apmContractPartnerContractStatusTypes} from '@ace-de/eua-entity-types';
import * as contractPartnerActionTypes from '../../contract-partners/contractPartnerActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import config from '../../config';
import modalIds from '../../modalIds';

const loadContractPartners = function* loadContractPartners() {
    const {contractPartners} = yield select(state => state.contractPartners);
    if (Object.values(contractPartners)?.length > 0) return;

    const {serviceManager} = yield select(state => state.application);
    const partnerManagerService = serviceManager.loadService('partnerManagementService');
    const {location} = yield select(state => state.router);

    // do not reload ace partners when the agent opens or closes a modal on the QM search screen
    if (location && (location.state?.isModalOpen || location.state?.isModalClosed)
        && [modalIds.CP_CREATE_QUALITY_REPORT].includes(location.state?.modalId)
    ) return;

    // if we are on the dashboard screen, check if there are query parameters
    // in order to filter APM contract partners (improves performances)
    const queryParams = new URLSearchParams(location.search || '');
    const contractStatuses = queryParams.getAll('contractStatus')
        .filter(status => Object.values(apmContractPartnerContractStatusTypes).includes(status));
    const regions = queryParams.getAll('region')
        .filter(region => Object.values(apmContractPartnerRegionTypes).includes(region));
    const searchQueryParams = new URLSearchParams();
    contractStatuses.forEach(status => searchQueryParams.append('contractStatus', status));
    if (regions.length) searchQueryParams.append('regions', regions);

    yield fork(
        fetchRequest,
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNERS_REQUEST,
        partnerManagerService.getACEPartnersV2,
        {
            partnerType: apmACEPartnerTypes.CONTRACT_PARTNER,
            country: config.CONTRACT_PARTNERS_SEARCH_COUNTRY_CODE,
            searchQueryParams,
        },
    );

    const responseAction = yield take([
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNERS_REQUEST_FAILED,
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNERS_REQUEST_SUCCEEDED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {acePartnerDTOs: contractPartnerDTOs} = response;

        yield put({
            type: contractPartnerActionTypes.STORE_CONTRACT_PARTNERS,
            payload: {contractPartnerDTOs},
        });
    }
};

export default loadContractPartners;
