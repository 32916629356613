/**
 * Command action types
 */
export const SEARCH_QUALITY_MANAGEMENT_FEEDBACKS = '[qm-feedbacks] SEARCH_QUALITY_MANAGEMENT_FEEDBACKS';
export const INITIATE_QUALITY_MANAGEMENT_FEEDBACKS_DOWNLOAD_FLOW = '[qm-feedbacks] INITIATE_QUALITY_MANAGEMENT_FEEDBACKS_DOWNLOAD_FLOW';
export const SUBMIT_QUALITY_MANAGEMENT_QUALIFICATION_EMAIL_FORM = '[qm-feedbacks] SUBMIT_QUALITY_MANAGEMENT_QUALIFICATION_EMAIL_FORM';
export const SUBMIT_QUALITY_MANAGEMENT_FEEDBACK_COMMENT = '[qm-feedbacks] SUBMIT_QUALITY_MANAGEMENT_FEEDBACK_COMMENT';
export const INITIATE_FORWARD_QM_FEEDBACK_FLOW = '[qm-feedbacks] INITIATE_FORWARD_QM_FEEDBACK_FLOW';
export const CONFIRM_FORWARD_QM_FEEDBACK = '[qm-feedbacks] CONFIRM_FORWARD_QM_FEEDBACK';
export const DECLINE_FORWARD_QM_FEEDBACK = '[qm-feedbacks] DECLINE_FORWARD_QM_FEEDBACK';
export const QUALIFY_QUALITY_MANAGEMENT_FEEDBACK = '[qm-feedbacks] QUALIFY_QUALITY_MANAGEMENT_FEEDBACK';
export const CLOSE_QUALITY_MANAGEMENT_FEEDBACK_ERROR_MODAL = '[qm-feedbacks] CLOSE_QUALITY_MANAGEMENT_FEEDBACK_ERROR_MODAL';
export const FILTER_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOGS = '[qm-feedbacks] FILTER_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOGS';
export const FETCH_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOG_SERVICES = '[qm-feedbacks] FETCH_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOG_SERVICES';
export const START_QM_FEEDBACK_MAP_CONTRACT_PARTNER_WATCHER = '[qm-feedbacks] START_QM_FEEDBACK_MAP_CONTRACT_PARTNER_WATCHER';
export const STOP_QM_FEEDBACK_MAP_CONTRACT_PARTNER_WATCHER = '[qm-feedbacks] STOP_QM_FEEDBACK_MAP_CONTRACT_PARTNER_WATCHER';
export const INITIATE_QM_FEEDBACK_CP_RANKING_MAP = '[qm-feedbacks] INITIATE_QM_FEEDBACK_CP_RANKING_MAP';
export const START_QM_FEEDBACK_FULL_SCREEN_MAP_CP_WATCHER = '[qm-feedbacks] START_QM_FEEDBACK_FULL_SCREEN_MAP_CP_WATCHER';
export const STOP_QM_FEEDBACK_FULL_SCREEN_MAP_CP_WATCHER = '[qm-feedbacks] STOP_QM_FEEDBACK_FULL_SCREEN_MAP_CP_WATCHER';
export const SUBMIT_CASE_LOG_DOCUMENT_DOWNLOAD_FLOW = '[qm-feedbacks] SUBMIT_CASE_LOG_DOCUMENT_DOWNLOAD_FLOW';

/**
 * Event action types
 */
export const SEARCH_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST = '[qm-feedbacks] SEARCH_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST';
export const SEARCH_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_SENT = '[qm-feedbacks] SEARCH_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_SENT';
export const SEARCH_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_SUCCEEDED = '[qm-feedbacks] SEARCH_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_SUCCEEDED';
export const SEARCH_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_FAILED = '[qm-feedbacks] SEARCH_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_FAILED';

export const DOWNLOAD_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST = '[qm-feedbacks] DOWNLOAD_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST';
export const DOWNLOAD_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_SENT = '[qm-feedbacks] DOWNLOAD_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_SENT';
export const DOWNLOAD_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_SUCCEEDED = '[qm-feedbacks] DOWNLOAD_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_SUCCEEDED';
export const DOWNLOAD_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_FAILED = '[qm-feedbacks] DOWNLOAD_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_FAILED';

export const FORWARD_QM_FEEDBACK_REQUEST = '[qm-feedbacks] FORWARD_QM_FEEDBACK_REQUEST';
export const FORWARD_QM_FEEDBACK_REQUEST_SENT = '[qm-feedbacks] FORWARD_QM_FEEDBACK_REQUEST_SENT';
export const FORWARD_QM_FEEDBACK_REQUEST_SUCCEEDED = '[qm-feedbacks] FORWARD_QM_FEEDBACK_REQUEST_SUCCEEDED';
export const FORWARD_QM_FEEDBACK_REQUEST_FAILED = '[qm-feedbacks] FORWARD_QM_FEEDBACK_REQUEST_FAILED';

export const FETCH_QM_FEEDBACKS_REQUEST = '[qm-feedbacks] FETCH_QM_FEEDBACKS_REQUEST';
export const FETCH_QM_FEEDBACKS_REQUEST_SENT = '[qm-feedbacks] FETCH_QM_FEEDBACKS_REQUEST_SENT';
export const FETCH_QM_FEEDBACKS_REQUEST_SUCCEEDED = '[qm-feedbacks] FETCH_QM_FEEDBACKS_REQUEST_SUCCEEDED';
export const FETCH_QM_FEEDBACKS_REQUEST_FAILED = '[qm-feedbacks] FETCH_QM_FEEDBACKS_REQUEST_FAILED';

export const CREATE_QUALITY_MANAGEMENT_FEEDBACK_COMMENT_REQUEST = '[qm-feedbacks] CREATE_QUALITY_MANAGEMENT_FEEDBACK_COMMENT_REQUEST';
export const CREATE_QUALITY_MANAGEMENT_FEEDBACK_COMMENT_REQUEST_SENT = '[qm-feedbacks] CREATE_QUALITY_MANAGEMENT_FEEDBACK_COMMENT_REQUEST_SENT';
export const CREATE_QUALITY_MANAGEMENT_FEEDBACK_COMMENT_REQUEST_SUCCEEDED = '[qm-feedbacks] CREATE_QUALITY_MANAGEMENT_FEEDBACK_COMMENT_REQUEST_SUCCEEDED';
export const CREATE_QUALITY_MANAGEMENT_FEEDBACK_COMMENT_REQUEST_FAILED = '[qm-feedbacks] CREATE_QUALITY_MANAGEMENT_FEEDBACK_COMMENT_REQUEST_FAILED';

export const FILTER_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOGS_REQUEST = '[qm-feedbacks] FILTER_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOGS_REQUEST';
export const FILTER_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOGS_REQUEST_SENT = '[qm-feedbacks] FILTER_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOGS_REQUEST_SENT';
export const FILTER_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOGS_REQUEST_SUCCEEDED = '[qm-feedbacks] FILTER_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOGS_REQUEST_SUCCEEDED';
export const FILTER_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOGS_REQUEST_FAILED = '[qm-feedbacks] FILTER_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOGS_REQUEST_FAILED';

export const FETCH_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOG_SERVICES_REQUEST = '[qm-feedbacks] FETCH_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOG_SERVICES_REQUEST';
export const FETCH_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOG_SERVICES_REQUEST_SENT = '[qm-feedbacks] FETCH_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOG_SERVICES_REQUEST_SENT';
export const FETCH_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOG_SERVICES_REQUEST_SUCCEEDED = '[qm-feedbacks] FETCH_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOG_SERVICES_REQUEST_SUCCEEDED';
export const FETCH_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOG_SERVICES_REQUEST_FAILED = '[qm-feedbacks] FETCH_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOG_SERVICES_REQUEST_FAILED';

export const QUALIFY_QUALITY_MANAGEMENT_FEEDBACK_REQUEST = '[qm-feedbacks] QUALIFY_QUALITY_MANAGEMENT_FEEDBACK_REQUEST';
export const QUALIFY_QUALITY_MANAGEMENT_FEEDBACK_REQUEST_SENT = '[qm-feedbacks] QUALIFY_QUALITY_MANAGEMENT_FEEDBACK_REQUEST_SENT';
export const QUALIFY_QUALITY_MANAGEMENT_FEEDBACK_REQUEST_SUCCEEDED = '[qm-feedbacks] QUALIFY_QUALITY_MANAGEMENT_FEEDBACK_REQUEST_SUCCEEDED';
export const QUALIFY_QUALITY_MANAGEMENT_FEEDBACK_REQUEST_FAILED = '[qm-feedbacks] QUALIFY_QUALITY_MANAGEMENT_FEEDBACK_REQUEST_FAILED';

export const DOWNLOAD_CASE_LOG_DOCUMENT_REQUEST = '[qm-feedbacks] DOWNLOAD_CASE_LOG_DOCUMENT_REQUEST';
export const DOWNLOAD_CASE_LOG_DOCUMENT_REQUEST_SENT = '[qm-feedbacks] DOWNLOAD_CASE_LOG_DOCUMENT_REQUEST_SENT';
export const DOWNLOAD_CASE_LOG_DOCUMENT_REQUEST_SUCCEEDED = '[qm-feedbacks] DOWNLOAD_CASE_LOG_DOCUMENT_REQUEST_SUCCEEDED';
export const DOWNLOAD_CASE_LOG_DOCUMENT_REQUEST_FAILED = '[qm-feedbacks] DOWNLOAD_CASE_LOG_DOCUMENT_REQUEST_FAILED';

/**
 * Store action types
 */
export const STORE_QUALITY_MANAGEMENT_FEEDBACKS_SEARCH_RESULTS = '[qm-feedbacks] STORE_QUALITY_MANAGEMENT_FEEDBACKS_SEARCH_RESULTS';
export const SET_SELECTED_FEEDBACK = '[qm-feedbacks] SET_SELECTED_FEEDBACK';
export const STORE_QM_FEEDBACK_FORWARDED_STATUS = '[qm-feedbacks] STORE_QM_FEEDBACK_FORWARDED_STATUS';
export const STORE_QUALITY_MANAGEMENT_FEEDBACKS = '[qm-feedbacks] STORE_QUALITY_MANAGEMENT_FEEDBACKS';
export const STORE_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOGS = '[qm-feedbacks] STORE_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOGS';
export const STORE_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOG_SERVICES = '[qm-feedbacks] STORE_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOG_SERVICES';
export const SET_ACTIVE_CONTRACT_PARTNER_ID = '[qm-feedbacks] SET_ACTIVE_CONTRACT_PARTNER_ID';
