import {put, select, take, fork, call} from 'redux-saga/effects';
import * as contractPartnerActionTypes from '../../contract-partners/contractPartnerActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';

const filterMapContractPartnersByDistance = function* filterMapContractPartnersByDistance(params) {
    const {contractStatuses, regionIds, locationId} = params;
    const {serviceManager} = yield select(state => state.application);
    const {isCPReloadButtonVisible, randomLocationCandidates, hasPolygonsError} = yield select(state => state.contractPartners); // eslint-disable-line max-len
    const arcGISMapService = serviceManager.loadService('arcGISMapService');
    const arcGISMap = yield call(arcGISMapService.getMap, 'vpm-contract-partners');

    if (!arcGISMap) return;

    const vpmContractPartnersLocationsLayer = yield call(arcGISMap.getLayer, 'vpm-contract-partner-locations');
    const vpmContractPartnersServiceAreasLayer = yield call(arcGISMap.getLayer, 'vpm-contract-partner-service-areas');

    if (!vpmContractPartnersServiceAreasLayer || !vpmContractPartnersLocationsLayer) return;

    const [location] = randomLocationCandidates
        ? randomLocationCandidates.filter(location => location['locationId'] === locationId) : [];

    if (!location) return;

    yield fork(
        fetchRequest,
        contractPartnerActionTypes.FILTER_CP_DISTANCE_RECOMMENDATIONS_REQUEST,
        vpmContractPartnersLocationsLayer.filterPointFeaturesByDistance,
        {
            distance: 100,
            referentialPoint: location,
            returnLocationFromAttribute: 'address',
            featureCount: 10,
            where: (contractStatuses.length > 0
                ? `(${contractStatuses.map(status => (`contractStatus = '${status}'`)).toString().replaceAll(',', ' OR ')})`
                : ``)
                + (regionIds.length > 0
                    ? `${contractStatuses.length > 0 ? ' AND ' : ''}(${regionIds.map(regionId => `regionId = '${regionId}'`).toString().replaceAll(',', ' OR ')})`
                    : ``),
        },
    );

    const filterPointFeaturesResponseAction = yield take([
        contractPartnerActionTypes.FILTER_CP_DISTANCE_RECOMMENDATIONS_REQUEST_FAILED,
        contractPartnerActionTypes.FILTER_CP_DISTANCE_RECOMMENDATIONS_REQUEST_SUCCEEDED,
    ]);


    if (filterPointFeaturesResponseAction.error) return;

    // reset 'hasPolygonsError' flag
    if (hasPolygonsError) {
        yield put({
            type: contractPartnerActionTypes.SET_CONTRACT_PARTNERS_MAP_POLYGONS_ERROR,
            payload: {
                hasPolygonsError: false,
            },
        });
    }

    const {response} = filterPointFeaturesResponseAction.payload;
    const {featureDTOs: recommendedContractPartnerDTOs = []} = response;

    if (recommendedContractPartnerDTOs.length) {
        yield fork(
            fetchRequest,
            contractPartnerActionTypes.FILTER_CP_SERVICE_AREAS_REQUEST,
            vpmContractPartnersServiceAreasLayer.filterFeaturesByAttribute,
            {
                where: `contractPa IN (${recommendedContractPartnerDTOs
                    .map(contractPartnerDTO => `'${contractPartnerDTO['contractPartnerId']}'`)
                    .join(', ')})`,
                referentialPoint: location,
            },
        );

        const filterPolygonFeaturesResponseAction = yield take([
            contractPartnerActionTypes.FILTER_CP_SERVICE_AREAS_REQUEST_SUCCEEDED,
            contractPartnerActionTypes.FILTER_CP_SERVICE_AREAS_REQUEST_FAILED,
        ]);

        if (filterPolygonFeaturesResponseAction.error) {
            // set 'hasPolygonsError' flag
            yield put({
                type: contractPartnerActionTypes.SET_CONTRACT_PARTNERS_MAP_POLYGONS_ERROR,
                payload: {
                    hasPolygonsError: true,
                },
            });
        }
    }

    yield put({
        type: contractPartnerActionTypes.SET_CONTRACT_PARTNER_RECOMMENDATIONS,
        payload: {recommendedContractPartnerDTOs},
    });

    if (isCPReloadButtonVisible) {
        yield put({
            type: contractPartnerActionTypes.SET_CONTRACT_PARTNERS_MAP_RELOAD_BUTTON_VISIBILITY,
            payload: {
                isVisible: false,
            },
        });
    }

    if (recommendedContractPartnerDTOs.length) {
        // center map and set zoom level so an agent can see all the features on the map
        const extent = yield call(vpmContractPartnersLocationsLayer.getFeatureLayerExtent, {
            where: `contractPa IN (${recommendedContractPartnerDTOs
                .map(contractPartnerDTO => `'${contractPartnerDTO['contractPartnerId']}'`)
                .join(', ')})`,
        });
        yield call(arcGISMap.setMapExtent, extent);
    }

    vpmContractPartnersLocationsLayer.show();
};
export default filterMapContractPartnersByDistance;
