import {take, select, put, fork} from 'redux-saga/effects';
import {ACEPartner, apmACEPartnerTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';

const updateContractPartnerRelationsFlow = function* updateContractPartnerRelationsFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    while (true) {
        const {payload} = yield take(contractPartnerActionTypes.INITIATE_CP_RELATIONS_UPDATE_FLOW);
        const {contractPartnerId, contractPartnerData} = payload;

        yield fork(
            fetchRequest,
            contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_RELATIONS_REQUEST,
            partnerManagementService.updateACEPartner,
            {
                acePartnerData: {
                    ...ACEPartner.objectToPatchDTO(contractPartnerData),
                    partnerType: apmACEPartnerTypes.CONTRACT_PARTNER,
                },
                acePartnerId: contractPartnerId,
            },
        );

        const updateContractPartnerStatusResponseAction = yield take([
            contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_RELATIONS_REQUEST_SUCCEEDED,
            contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_RELATIONS_REQUEST_FAILED,
        ]);

        if (!updateContractPartnerStatusResponseAction.error) {
            const {response} = updateContractPartnerStatusResponseAction.payload;
            const {acePartnerDTO} = response;

            yield put({
                type: contractPartnerActionTypes.STORE_CONTRACT_PARTNERS,
                payload: {contractPartnerDTOs: [acePartnerDTO]},
            });
        }
    }
};

export default updateContractPartnerRelationsFlow;
