import {take, put, select, fork} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {OperatingUnit} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import modalIds from '../../modalIds';

const createCPOperatingUnitFlow = function* createCPOperatingUnitFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    while (true) {
        const {payload} = yield take(contractPartnerActionTypes.INITIATE_CREATE_CP_OPERATING_UNIT_FLOW);
        const {contractPartnerId} = payload;

        yield* openModal(modalIds.CP_OPERATING_UNIT_DATA);

        const chosenModalOption = yield take([
            contractPartnerActionTypes.CONFIRM_CREATE_CP_OPERATING_UNIT,
            contractPartnerActionTypes.DECLINE_CREATE_CP_OPERATING_UNIT,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === contractPartnerActionTypes.CONFIRM_CREATE_CP_OPERATING_UNIT
        ) {
            const {operatingUnitData} = chosenModalOption.payload;

            yield fork(
                fetchRequest,
                contractPartnerActionTypes.CREATE_CP_OPERATING_UNIT_REQUEST,
                partnerManagementService.createCPOperatingUnit,
                {
                    operatingUnitDTO: OperatingUnit.objectToDTO(operatingUnitData),
                },
            );

            const responseAction = yield take([
                contractPartnerActionTypes.CREATE_CP_OPERATING_UNIT_REQUEST_SUCCEEDED,
                contractPartnerActionTypes.CREATE_CP_OPERATING_UNIT_REQUEST_FAILED,
            ]);

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {operatingUnitDTO} = response;

                yield put({
                    type: contractPartnerActionTypes.STORE_CP_OPERATING_UNIT,
                    payload: {operatingUnitDTO, contractPartnerId},
                });
            }
        }

        yield* closeModal(modalIds.CP_OPERATING_UNIT_DATA);
    }
};

export default createCPOperatingUnitFlow;
