import {put, select, take, fork, call} from 'redux-saga/effects';
import * as contractPartnerActionTypes from '../../contract-partners/contractPartnerActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import config from '../../config';

const filterMapContractPartnersByLocation = function* filterMapContractPartnersByLocation(params) {
    const {contractStatuses, regionIds, centerCoordinates = null, isDefaultSearch} = params;
    const {serviceManager} = yield select(state => state.application);
    const {isCPReloadButtonVisible, hasPolygonsError} = yield select(state => state.contractPartners);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');
    const arcGISMap = yield call(arcGISMapService.getMap, 'vpm-contract-partners');

    if (!arcGISMap) return;

    const vpmContractPartnersLocationsLayer = yield call(arcGISMap.getLayer, 'vpm-contract-partner-locations');
    const vpmContractPartnersServiceAreasLayer = yield call(arcGISMap.getLayer, 'vpm-contract-partner-service-areas');

    if (!vpmContractPartnersServiceAreasLayer || !vpmContractPartnersLocationsLayer) return;

    const spatialReference = vpmContractPartnersLocationsLayer.getServiceFeatureLayer().spatialReference;

    // check zoom level: if it's lower than MINIMUM_MAP_ZOOM_LEVEL_FOR_SEARCHING, the page was reloaded
    if (arcGISMap.getMapViewZoomLevel() < config.MINIMUM_MAP_ZOOM_LEVEL_FOR_SEARCHING) {
        arcGISMap.setMapViewZoomLevel(config.DEFAULT_ARCGIS_MAP_WIDGET_ZOOM_LEVEL);
        arcGISMap.setMapCenter(config.STUTTGART_CENTER_COORDINATES);
    }
    // if there are center coordinates, the page was reloaded when an agent searches for CPs in the area
    if (centerCoordinates) {
        arcGISMap.setMapCenter(centerCoordinates);
    }
    // when an agent clicks on the logo, apply default search
    if (isDefaultSearch) {
        arcGISMap.setMapCenter(config.STUTTGART_CENTER_COORDINATES);
    }
    const mapCenter = arcGISMap.getMapCenter();
    const arcGISMapCenterPoint = yield call(arcGISMap.createPoint, mapCenter.x, mapCenter.y, spatialReference);
    const mapExtent = yield call(arcGISMap.getMapExtent);

    yield fork(
        fetchRequest,
        contractPartnerActionTypes.FILTER_CP_DISTANCE_RECOMMENDATIONS_REQUEST,
        vpmContractPartnersLocationsLayer.filterPointFeaturesByMapExtent,
        {
            extentGeometry: mapExtent,
            returnLocationFromAttribute: 'address',
            where: (contractStatuses.length > 0
                ? `(${contractStatuses.map(status => (`contractStatus = '${status}'`)).toString().replaceAll(',', ' OR ')})`
                : ``)
                + (regionIds.length > 0
                    ? `${contractStatuses.length > 0 ? ' AND ' : ''}(${regionIds.map(regionId => `regionId = '${regionId}'`).toString().replaceAll(',', ' OR ')})`
                    : ``),
        },
    );

    const filterPointFeaturesResponseAction = yield take([
        contractPartnerActionTypes.FILTER_CP_DISTANCE_RECOMMENDATIONS_REQUEST_FAILED,
        contractPartnerActionTypes.FILTER_CP_DISTANCE_RECOMMENDATIONS_REQUEST_SUCCEEDED,
    ]);


    if (filterPointFeaturesResponseAction.error) return;

    // reset 'hasPolygonsError' flag
    if (hasPolygonsError) {
        yield put({
            type: contractPartnerActionTypes.SET_CONTRACT_PARTNERS_MAP_POLYGONS_ERROR,
            payload: {
                hasPolygonsError: false,
            },
        });
    }

    const {response} = filterPointFeaturesResponseAction.payload;
    const {featureDTOs: recommendedContractPartnerDTOs = []} = response;

    if (recommendedContractPartnerDTOs.length) {
        yield fork(
            fetchRequest,
            contractPartnerActionTypes.FILTER_CP_SERVICE_AREAS_REQUEST,
            vpmContractPartnersServiceAreasLayer.filterFeaturesByAttribute,
            {
                where: `contractPa IN (${recommendedContractPartnerDTOs
                    .map(contractPartnerDTO => `'${contractPartnerDTO['contractPartnerId']}'`)
                    .join(', ')})`,
                referentialPoint: arcGISMapCenterPoint,
            },
        );

        const filterPolygonFeaturesResponseAction = yield take([
            contractPartnerActionTypes.FILTER_CP_SERVICE_AREAS_REQUEST_SUCCEEDED,
            contractPartnerActionTypes.FILTER_CP_SERVICE_AREAS_REQUEST_FAILED,
        ]);

        if (filterPolygonFeaturesResponseAction.error) {
            // set 'hasPolygonsError' flag
            yield put({
                type: contractPartnerActionTypes.SET_CONTRACT_PARTNERS_MAP_POLYGONS_ERROR,
                payload: {
                    hasPolygonsError: true,
                },
            });
        }
    }

    yield put({
        type: contractPartnerActionTypes.SET_CONTRACT_PARTNER_RECOMMENDATIONS,
        payload: {recommendedContractPartnerDTOs},
    });

    if (isCPReloadButtonVisible) {
        yield put({
            type: contractPartnerActionTypes.SET_CONTRACT_PARTNERS_MAP_RELOAD_BUTTON_VISIBILITY,
            payload: {
                isVisible: false,
            },
        });
    }

    vpmContractPartnersLocationsLayer.show();
};
export default filterMapContractPartnersByLocation;
