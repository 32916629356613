import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, ButtonPrimary, Divider} from '@ace-de/ui-components';
import {Icon, checkmarkIcon, closeIcon, editIcon} from '@ace-de/ui-components/icons';
import * as contractPartnerSelectors from './contractPartnerSelectors';
import * as contractPartnerActionTypes from './contractPartnerActionTypes';

const ContractPartnerEllaActivePanel = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_basic_data_tab');
    const {contractPartner, initiateCPCreateELLAAccount, initiateCPEditELLAAccount} = props;

    // if no contract partner, don't render
    if (!contractPartner) return null;

    return (
        <Panel
            title={translateTab('panel_title.invoice')}
            actions={!contractPartner.affiliatedPartner && contractPartner.hasELLAAccount
                ? (<Icon icon={editIcon} onClick={initiateCPEditELLAAccount} />)
                : (<Fragment />)}
        >
            <div
                className={cx([
                    'global!ace-u-full-width',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-space-between',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-padding--16',
                ])}
            >
                <span className={cx('global!ace-u-typography--variant-body')}>
                    {translate(`contract_partner_basic_data_tab.label.is_ella_account_active`)}
                </span>
                <Icon
                    icon={(contractPartner.ellaActive && contractPartner.hasELLAAccount)
                        || (contractPartner.affiliatedPartner?.ellaActive
                            && contractPartner.affiliatedPartner?.hasELLAAccount)
                        ? checkmarkIcon : closeIcon}
                    className={cx((contractPartner.ellaActive && contractPartner.hasELLAAccount)
                        || (contractPartner.affiliatedPartner?.ellaActive
                            && contractPartner.affiliatedPartner?.hasELLAAccount)
                        ? 'ace-c-icon--color-success'
                        : 'ace-c-icon--color-warning')}
                />
            </div>
            {!contractPartner.affiliatedPartner && !contractPartner.hasELLAAccount && (
                <Fragment>
                    <Divider />
                    <ButtonPrimary
                        name="createELLAAccountButton"
                        onClick={() => initiateCPCreateELLAAccount({
                            contractPartnerId: contractPartner.id,
                            contractPartnerName: contractPartner.name,
                            affiliatePartnersIds: contractPartner.affiliatePartners?.length
                                ? contractPartner.affiliatePartners.map(cp => cp.id).filter(id => !!id)
                                : [],
                        })}
                        className={cx([
                            'global!ace-u-full-width',
                            'global!ace-u-margin--top-32',
                        ])}
                    >
                        {translateTab('button_label.create_ella_account')}
                    </ButtonPrimary>
                </Fragment>
            )}
        </Panel>
    );
};

ContractPartnerEllaActivePanel.propTypes = {
    contractPartner: PropTypes.object,
    initiateCPCreateELLAAccount: PropTypes.func.isRequired,
    initiateCPEditELLAAccount: PropTypes.func.isRequired,
};

ContractPartnerEllaActivePanel.defaultProps = {
    contractPartner: null,
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateCPCreateELLAAccount: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_CP_CREATE_ELLA_ACCOUNT_FLOW,
        payload,
    }),
    initiateCPEditELLAAccount: () => dispatch({
        type: contractPartnerActionTypes.INITIATE_CP_EDIT_ELLA_ACCOUNT_FLOW,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractPartnerEllaActivePanel));
