import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Modal, ButtonPrimary, HighlightCircle} from '@ace-de/ui-components';
import {Icon, closeIcon, plusIcon, deleteIcon, acceptedIcon} from '@ace-de/ui-components/icons';
import {Form, InputWithButtonField, TextAreaField} from '@ace-de/ui-components/form';
import * as qualityManagementFeedbackActionTypes from '../qualityManagementFeedbackActionTypes';
import * as qualityManagementFeedbackSelectors from '../qualityManagementFeedbackSelectors';
import config from '../../config';
import {validateEmail} from '../../utils/validation';
import qmFeedbackForwardingStatusTypes from '../qmFeedbackForwardingStatusTypes';

const ForwardQMFeedbackModal = props => {
    const {cx} = useStyles();
    const {hasBackdrop, qmFeedback, qmFeedbackForwardingStatus} = props;
    const {confirmForwardQMFeedback, declineForwardQMFeedback} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('forward_qm_feedback_modal');
    const [emailAddresses, setEmailAddresses] = useState([]);
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);

    const addEmailAddress = formValues => {
        if (!validateEmail((formValues.email))) {
            setIsEmailValid(false);
            return;
        }
        const newEmailAddresses = emailAddresses;
        if (!emailAddresses.find(emailAddress => emailAddress === formValues.email)) {
            newEmailAddresses.push(formValues.email);
        }
        setEmailAddresses([...newEmailAddresses]);
        setEmail('');
        setIsEmailValid(true);
    };

    const handleOnSubmit = formValues => {
        if (!emailAddresses.length) return;

        confirmForwardQMFeedback({
            emailData: {
                emails: emailAddresses,
                message: formValues.message,
            },
            feedbackId: qmFeedback.id,
        });
    };

    // if no qmFeedback, don't render
    if (!qmFeedback) return null;

    return (
        <Modal
            title={translateModal('title.forward_qm')}
            action={(
                <Icon icon={closeIcon} onClick={() => declineForwardQMFeedback()} />
            )}
            hasBackdrop={hasBackdrop}
            contentClassName={cx('global!ace-u-margin--top-16')}
        >
            {qmFeedbackForwardingStatus !== qmFeedbackForwardingStatusTypes.SUCCEEDED ? (
                <div className={cx('global!ace-u-full-width')}>
                    <Form name="forwardQMFeedbackForm" onSubmit={handleOnSubmit}>
                        <div
                            className={cx([
                                'global!ace-u-margin--bottom-16',
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-flex-end',
                            ])}
                        >
                            <Form name="emailForm" onSubmit={addEmailAddress}>
                                <InputWithButtonField
                                    name="email"
                                    className={cx('global!ace-u-full-width')}
                                    label={translateModal('input_label.email')}
                                    placeholder={translateModal('input_placeholder.email')}
                                    value={email}
                                    onChange={value => setEmail(value)}
                                    buttonType="submit"
                                    errors={!isEmailValid ? [translateModal('input_error.email')] : []}
                                >
                                    <Icon
                                        icon={plusIcon}
                                        className={cx('ace-c-icon--color-contrast')}
                                    />
                                </InputWithButtonField>
                            </Form>
                        </div>
                        {emailAddresses.length > 0 && emailAddresses.map((email, index) => (
                            <div
                                key={index}
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-margin--top-4',
                                ])}
                            >
                                <Icon
                                    icon={deleteIcon}
                                    onClick={() => setEmailAddresses(prevState => {
                                        return [...prevState.filter(email => (
                                            prevState.indexOf(email) !== index))];
                                    })}
                                />
                                <p className={cx('global!ace-u-margin--left-8')}>{email}</p>
                            </div>
                        ))}
                        <TextAreaField
                            name="message"
                            label={translateModal('input_label.message')}
                            maxLength={config.MAXIMUM_QMN_DESCRIPTION_TEXT_AREA_CONTENT_LENGTH}
                            isResizable={false}
                            className={cx(['global!ace-u-full-width', 'global!ace-u-margin--top-16'])}
                            value=""
                        />
                        {qmFeedbackForwardingStatus === qmFeedbackForwardingStatusTypes.FAILED && (
                            <p
                                className={cx([
                                    'global!ace-u-typography--variant-caption',
                                    'global!ace-u-typography--color-warning',
                                    'global!ace-u-typography--align-right',
                                    'global!ace-u-margin--top-16',
                                ])}
                            >
                                {translateModal('error_message.forwarding_failed')}
                            </p>
                        )}
                        <div
                            className={cx([
                                'global!ace-u-margin--top-16',
                                'global!ace-u-flex',
                                'global!ace-u-flex--direction-row-reverse',
                            ])}
                        >
                            <ButtonPrimary name="forwardQMFeedbackButton" type="submit" isDisabled={!emailAddresses?.length}>
                                {translateModal('button_label.send')}
                            </ButtonPrimary>
                        </div>
                    </Form>
                </div>
            ) : (
                <div
                    className={cx([
                        'global!ace-u-margin--bottom-64',
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-column',
                        'global!ace-u-flex--justify-center',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-full-width',
                    ])}
                >
                    <HighlightCircle
                        className={cx([
                            'ace-c-highlight-circle--medium',
                            'global!ace-u-margin--bottom-32',
                            'ace-c-highlight-circle--success',
                        ])}
                    >
                        <Icon
                            icon={acceptedIcon}
                            className={cx([
                                'ace-c-icon--xxl',
                                'ace-c-icon--color-success',
                            ])}
                        />
                    </HighlightCircle>
                    <h3 className={cx('global!ace-u-typography--variant-h3')}>
                        {translateModal('success_message.title')}
                    </h3>
                    <p
                        className={cx([
                            'global!ace-u-margin--top-32',
                            'global!ace-u-typography--align-center',
                            'global!ace-u-typography--variant-body',
                        ])}
                    >
                        {translateModal('success_message.text')}
                    </p>
                </div>
            )}
        </Modal>
    );
};

ForwardQMFeedbackModal.propTypes = {
    confirmForwardQMFeedback: PropTypes.func.isRequired,
    declineForwardQMFeedback: PropTypes.func.isRequired,
    hasBackdrop: PropTypes.bool,
    qmFeedback: PropTypes.object,
    qmFeedbackForwardingStatus: PropTypes.string,
};

ForwardQMFeedbackModal.defaultProps = {
    hasBackdrop: true,
    qmFeedback: null,
    qmFeedbackForwardingStatus: '',
};

const mapStateToProps = (state, props) => {
    const qmFeedbackSelector = qualityManagementFeedbackSelectors.createQMFeedbackSelector();

    return {
        qmFeedback: qmFeedbackSelector(state, props),
        qmFeedbackForwardingStatus: state.qualityManagementFeedbacks.qmFeedbackForwardingStatus,
    };
};

const mapDispatchToProps = dispatch => ({
    confirmForwardQMFeedback: payload => dispatch({
        type: qualityManagementFeedbackActionTypes.CONFIRM_FORWARD_QM_FEEDBACK,
        payload,
    }),
    declineForwardQMFeedback: payload => dispatch({
        type: qualityManagementFeedbackActionTypes.DECLINE_FORWARD_QM_FEEDBACK,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForwardQMFeedbackModal);
