import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {Button, ButtonPrimary, Checkbox, Divider, Modal, useStyles} from '@ace-de/ui-components';
import * as contractPartnerSelectors from '../contractPartnerSelectors';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';

const CPEditELLAAccountModal = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('cp_edit_ella_account_modal');
    const {hasBackdrop, contractPartner, confirmCPELLAAccountEdit, declineCPELLAAccountEdit} = props;
    const [ellaAccountActive, setEllaAccountActive] = useState();

    useEffect(() => {
        if (!contractPartner) return;
        setEllaAccountActive(contractPartner.ellaActive);
    }, [contractPartner]);

    return (
        <Modal
            title={translateModal('title.edit_ella_account')}
            hasBackdrop={hasBackdrop}
            contentClassName={cx('global!ace-u-modal-content-size--xs')}
        >
            <div
                className={cx([
                    'global!ace-u-margin--16',
                    'global!ace-u-width--full',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-space-between',
                    'global!ace-u-flex--align-center',
                ])}
            >
                <span
                    className={cx('global!ace-u-typography--variant-body')}
                >
                    {translateModal('checkbox_label.ella_account_active')}
                </span>
                <Checkbox
                    name="ellaAccountActive"
                    value={true}
                    isSelected={!!ellaAccountActive}
                    className={cx('global!ace-u-margin--bottom-16')}
                    onChange={value => setEllaAccountActive(value)}
                />
            </div>
            <Divider />
            <div
                className={cx([
                    'global!ace-u-padding--24',
                    'global!ace-u-width--full',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-flex--align-center',
                ])}
            >
                <Button onClick={declineCPELLAAccountEdit}>
                    {translateModal('button_label.discard')}
                </Button>
                <ButtonPrimary
                    className={cx('global!ace-u-margin--left-24')}
                    onClick={() => confirmCPELLAAccountEdit({ellaAccountActive, contractPartnerId: contractPartner.id})}
                >
                    {translateModal('button_label.save')}
                </ButtonPrimary>
            </div>
        </Modal>
    );
};

CPEditELLAAccountModal.propTypes = {
    contractPartner: PropTypes.object,
    hasBackdrop: PropTypes.bool,
    declineCPELLAAccountEdit: PropTypes.func.isRequired,
    confirmCPELLAAccountEdit: PropTypes.func.isRequired,
};

CPEditELLAAccountModal.defaultProps = {
    contractPartner: null,
    hasBackdrop: true,
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    declineCPELLAAccountEdit: () => dispatch({
        type: contractPartnerActionTypes.DECLINE_CP_ELLA_ACCOUNT_EDIT_FLOW,
    }),
    confirmCPELLAAccountEdit: payload => dispatch({
        type: contractPartnerActionTypes.CONFIRM_CP_ELLA_ACCOUNT_EDIT_FLOW,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CPEditELLAAccountModal);
