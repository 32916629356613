import {take, select, put, fork} from 'redux-saga/effects';
import {ACEPartner, apmACEPartnerTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';

const updateContractPartnerAdditionalServicesFlow = function* updateContractPartnerAdditionalServicesFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    while (true) {
        const {payload} = yield take(contractPartnerActionTypes.SUBMIT_CONTRACT_PARTNER_ADDITIONAL_SERVICES_FORM);
        const {contractPartnerId, contractPartnerData} = payload;

        yield fork(
            fetchRequest,
            contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_ADDITIONAL_SERVICES_REQUEST,
            partnerManagementService.updateACEPartner,
            {
                acePartnerData: {
                    ...ACEPartner.objectToPatchDTO(contractPartnerData),
                    partnerType: apmACEPartnerTypes.CONTRACT_PARTNER,
                },
                acePartnerId: contractPartnerId,
            },
        );
        const responseAction = yield take([
            contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_ADDITIONAL_SERVICES_REQUEST_SUCCEEDED,
            contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_ADDITIONAL_SERVICES_REQUEST_FAILED,
        ]);

        if (!responseAction.error) {
            const {response} = responseAction.payload;
            const {acePartnerDTO} = response;

            yield put({
                type: contractPartnerActionTypes.STORE_CONTRACT_PARTNERS,
                payload: {contractPartnerDTOs: [acePartnerDTO]},
            });
        }
    }
};

export default updateContractPartnerAdditionalServicesFlow;
