/**
 * Command action types
 */
export const SEARCH_CONTRACT_PARTNERS = '[contract-partners] SEARCH_CONTRACT_PARTNERS';
export const START_MAP_CONTRACT_PARTNER_WATCHER = '[contract-partners] START_MAP_CONTRACT_PARTNER_WATCHER';
export const STOP_MAP_CONTRACT_PARTNER_WATCHER = '[contract-partners] STOP_MAP_CONTRACT_PARTNER_WATCHER';
export const INITIATE_CP_ASSIGNMENT_CHANNELS_UPDATE_FLOW = '[contract-partners] INITIATE_CP_ASSIGNMENT_CHANNELS_UPDATE_FLOW';
export const CONFIRM_CP_ASSIGNMENT_CHANNELS_UPDATE = '[contract-partners] CONFIRM_CP_ASSIGNMENT_CHANNELS_UPDATE';
export const DECLINE_CP_ASSIGNMENT_CHANNELS_UPDATE = '[contract-partners] DECLINE_CP_ASSIGNMENT_CHANNELS_UPDATE';
export const INITIATE_CONTRACT_PARTNER_STATUS_UPDATE_FLOW = '[contract-partners] INITIATE_CONTRACT_PARTNER_STATUS_UPDATE_FLOW';
export const CONFIRM_CONTRACT_PARTNER_STATUS_UPDATE = '[contract-partners] CONFIRM_CONTRACT_PARTNER_STATUS_UPDATE';
export const DECLINE_CONTRACT_PARTNER_STATUS_UPDATE = '[contract-partners] DECLINE_CONTRACT_PARTNER_STATUS_UPDATE';
export const INITIATE_CONTRACT_PARTNER_EMERGENCY_CALL_DATA_UPDATE_FLOW = '[contract-partners] INITIATE_CONTRACT_PARTNER_EMERGENCY_CALL_DATA_UPDATE_FLOW';
export const SUBMIT_CP_CONTACT_DETAILS_FORM = '[contract-partners] SUBMIT_CP_CONTACT_DETAILS_FORM';
export const INITIATE_CREATE_TEMPORARY_RESTRICTION_FLOW = '[contract-partners] INITIATE_CREATE_TEMPORARY_RESTRICTION_FLOW';
export const DECLINE_CREATE_TEMPORARY_RESTRICTION = '[contract-partners] DECLINE_CREATE_TEMPORARY_RESTRICTION';
export const CONFIRM_CREATE_TEMPORARY_RESTRICTION = '[contract-partners] CONFIRM_CREATE_TEMPORARY_RESTRICTION';
export const INITIATE_EDIT_TEMPORARY_RESTRICTION_FLOW = '[contract-partners] INITIATE_EDIT_TEMPORARY_RESTRICTION_FLOW';
export const INITIATE_CREATE_CONTRACT_PARTNER_QUALITY_REPORT_FLOW = '[contract-partners] INITIATE_CREATE_CONTRACT_PARTNER_QUALITY_REPORT_FLOW';
export const DECLINE_CREATE_CONTRACT_PARTNER_QUALITY_REPORT = '[contract-partners] DECLINE_CREATE_CONTRACT_PARTNER_QUALITY_REPORT';
export const CONFIRM_CREATE_CONTRACT_PARTNER_QUALITY_REPORT = '[contract-partners] CONFIRM_CREATE_CONTRACT_PARTNER_QUALITY_REPORT';
export const CONFIRM_EDIT_TEMPORARY_RESTRICTION = '[contract-partners] CONFIRM_EDIT_TEMPORARY_RESTRICTION';
export const DECLINE_EDIT_TEMPORARY_RESTRICTION = '[contract-partners] DECLINE_EDIT_TEMPORARY_RESTRICTION';
export const SUBMIT_CONTRACT_PARTNER_ACCOUNT_MANAGER_FORM = '[contract-partners] SUBMIT_CONTRACT_PARTNER_ACCOUNT_MANAGER_FORM';
export const SEARCH_CONTRACT_PARTNER_BY_ATTRIBUTE = '[contract-partners] SEARCH_CONTRACT_PARTNER_BY_ATTRIBUTE';
export const SEARCH_RANDOM_LOCATION_GEOLOCATION = '[contract-partners] SEARCH_RANDOM_LOCATION_GEOLOCATION';
export const INITIATE_CONTRACT_PARTNER_SELECT_FLOW = '[contract-partners] INITIATE_CONTRACT_PARTNER_SELECT_FLOW';
export const INITIATE_CHANGE_CP_SERVICE_AREAS_LAYER_VISIBILITY_STATE = '[contract-partners] INITIATE_CHANGE_CP_SERVICE_AREAS_LAYER_VISIBILITY_STATE';
export const INITIATE_CP_RELATIONS_UPDATE_FLOW = '[contract-partners] INITIATE_CP_RELATIONS_UPDATE_FLOW';
export const INITIATE_CP_BASIC_SERVICES_UPDATE_FLOW = '[contract-partners] INITIATE_CP_BASIC_SERVICES_UPDATE_FLOW';
export const SUBMIT_CONTRACT_PARTNER_ADDITIONAL_SERVICES_FORM = '[contract-partners] SUBMIT_CONTRACT_PARTNER_ADDITIONAL_SERVICES_FORM';
export const INITIATE_CP_SERVICES_UPDATE = '[contract-partners] INITIATE_CP_SERVICES_UPDATE';
export const SEARCH_SERVICE_CASES = '[contract-partners] SEARCH_SERVICE_CASES';
export const SUBMIT_CONTRACT_PARTNER_EXTERNAL_COMMISSIONERS_FORM = '[contract-partners] SUBMIT_CONTRACT_PARTNER_EXTERNAL_COMMISSIONERS_FORM';
export const SUBMIT_CONTRACT_PARTNER_ADDITIONAL_FEATURES_FORM = '[contract-partners] SUBMIT_CONTRACT_PARTNER_ADDITIONAL_FEATURES_FORM';
export const INITIATE_CP_CREATE_ELLA_ACCOUNT_FLOW = '[contract-partners] INITIATE_CP_CREATE_ELLA_ACCOUNT_FLOW';
export const CONFIRM_CREATE_CP_ELLA_ACCOUNT = '[contract-partners] CONFIRM_CREATE_CP_ELLA_ACCOUNT';
export const DECLINE_CREATE_CP_ELLA_ACCOUNT = '[contract-partners] DECLINE_CREATE_CP_ELLA_ACCOUNT';
export const SEARCH_CP_QUALITY_MANAGEMENT_FEEDBACKS = '[contract-partners] SEARCH_CP_QUALITY_MANAGEMENT_FEEDBACKS';
export const INITIATE_DELETE_CP_OPERATING_UNIT_FLOW = '[contract-partners] INITIATE_DELETE_CP_OPERATING_UNIT_FLOW';
export const CONFIRM_DELETE_CP_OPERATING_UNIT = '[contract-partners] CONFIRM_DELETE_CP_OPERATING_UNIT';
export const DECLINE_DELETE_CP_OPERATING_UNIT = '[contract-partners] DECLINE_DELETE_CP_OPERATING_UNIT';
export const INITIATE_UPDATE_CP_OPERATING_UNIT_FLOW = '[contract-partners] INITIATE_UPDATE_CP_OPERATING_UNIT_FLOW';
export const CONFIRM_UPDATE_CP_OPERATING_UNIT = '[contract-partners] CONFIRM_UPDATE_CP_OPERATING_UNIT';
export const DECLINE_UPDATE_CP_OPERATING_UNIT = '[contract-partners] DECLINE_UPDATE_CP_OPERATING_UNIT';
export const INITIATE_CREATE_CP_OPERATING_UNIT_FLOW = '[contract-partners] INITIATE_CREATE_CP_OPERATING_UNIT_FLOW';
export const CONFIRM_CREATE_CP_OPERATING_UNIT = '[contract-partners] CONFIRM_CREATE_CP_OPERATING_UNIT';
export const DECLINE_CREATE_CP_OPERATING_UNIT = '[contract-partners] DECLINE_CREATE_CP_OPERATING_UNIT';
export const SEARCH_CP_OPERATING_UNIT_LOCATION_GEOLOCATION = '[contract-partners] SEARCH_CP_OPERATING_UNIT_LOCATION_GEOLOCATION';
export const INITIATE_CONTRACT_PARTNER_OPERATION_AREA_SELECT_FLOW = '[contract-partners] INITIATE_CONTRACT_PARTNER_OPERATION_AREA_SELECT_FLOW';
export const START_MAP_CP_OPERATION_AREA_WATCHER = '[contract-partners] START_MAP_CP_OPERATION_AREA_WATCHER';
export const STOP_MAP_CP_OPERATION_AREA_WATCHER = '[contract-partners] STOP_MAP_CP_OPERATION_AREA_WATCHER';
export const INITIATE_CREATE_CP_CONTACT_DATA_FLOW = '[contract-partners] INITIATE_CREATE_CP_CONTACT_DATA_FLOW';
export const CONFIRM_CREATE_CP_CONTACT_DATA = '[contract-partners] CONFIRM_CREATE_CP_CONTACT_DATA';
export const DECLINE_CREATE_CP_CONTACT_DATA = '[contract-partners] DECLINE_CREATE_CP_CONTACT_DATA';
export const INITIATE_DELETE_CP_CONTACT_DATA_FLOW = '[contract-partners] INITIATE_DELETE_CP_CONTACT_DATA_FLOW';
export const CONFIRM_DELETE_CP_CONTACT_DATA = '[contract-partners] CONFIRM_DELETE_CP_CONTACT_DATA';
export const DECLINE_DELETE_CP_CONTACT_DATA = '[contract-partners] DECLINE_DELETE_CP_CONTACT_DATA';
export const INITIATE_EDIT_CP_CONTACT_DATA_FLOW = '[contract-partners] INITIATE_EDIT_CP_CONTACT_DATA_FLOW';
export const CONFIRM_EDIT_CP_CONTACT_DATA = '[contract-partners] CONFIRM_EDIT_CP_CONTACT_DATA';
export const DECLINE_EDIT_CP_CONTACT_DATA = '[contract-partners] DECLINE_EDIT_CP_CONTACT_DATA';
export const INITIATE_CP_OVERVIEW_DOWNLOAD_FLOW = '[contract-partners] INITIATE_CP_OVERVIEW_DOWNLOAD_FLOW';
export const INITIATE_UPDATE_CP_LOCATION_DATA = '[contract-partners] INITIATE_UPDATE_CP_LOCATION_DATA';
export const SEARCH_CP_LOCATION_GEOLOCATION = '[contract-partners] SEARCH_CP_LOCATION_GEOLOCATION';
export const INITIATE_CONTRACT_PARTNER_OPERATION_AREAS_MAP = '[contract-partners] INITIATE_CONTRACT_PARTNER_OPERATION_AREAS_MAP';
export const FETCH_CONTRACT_PARTNER_BC_DETAILS = '[contract-partners] FETCH_CONTRACT_PARTNER_BC_DETAILS';
export const INITIATE_BC_CONTACT_MODAL_FLOW = '[contract-partners] INITIATE_BC_CONTACT_MODAL_FLOW';
export const DECLINE_BC_CONTACT_MODAL_FLOW = '[contract-partners] DECLINE_BC_CONTACT_MODAL_FLOW';
export const SEARCH_BC_CONTACTS = '[contract-partners] SEARCH_BC_CONTACTS';
export const INITIATE_CONTRACT_PARTNER_CREATION_MODAL_FLOW = '[contract-partners] INITIATE_CONTRACT_PARTNER_CREATION_MODAL_FLOW';
export const INITIATE_CREDITOR_CREATION_MODAL_FLOW = '[contract-partners] INITIATE_CREDITOR_CREATION_MODAL_FLOW';
export const SUBMIT_CREDITOR_CREATION = '[contract-partners] SUBMIT_CREDITOR_CREATION';
export const SUBMIT_CONTRACT_PARTNER_CREATION = '[contract-partners] SUBMIT_CONTRACT_PARTNER_CREATION';
export const RETRY_CREDITOR_CREATION = '[contract-partners] RETRY_CREDITOR_CREATION';
export const RETRY_CONTACT_CREATION = '[contract-partners] RETRY_CONTACT_CREATION';
export const INITIATE_BC_CONTACT_UPDATE_MODAL_FLOW = '[contract-partners] INITIATE_BC_CONTACT_UPDATE_MODAL_FLOW';
export const INITIATE_BC_CREDITOR_UPDATE_MODAL_FLOW = '[contract-partners] INITIATE_BC_CREDITOR_UPDATE_MODAL_FLOW';
export const SUBMIT_UPDATE_CONTRACT_PARTNER = '[contract-partners] SUBMIT_UPDATE_CONTRACT_PARTNER';
export const SUBMIT_UPDATE_CREDITOR = '[contract-partners] SUBMIT_UPDATE_CREDITOR';
export const RETRY_UPDATE_CONTACT = '[contract-partners] RETRY_UPDATE_CONTACT';
export const RETRY_UPDATE_CREDITOR = '[contract-partners] RETRY_UPDATE_CREDITOR';
export const RETURN_TO_CONTACT_SEARCH = '[contract-partners] RETURN_TO_CONTACT_SEARCH';
export const CANCEL_ACTION = '[contract-partners] CANCEL_ACTION';
export const INITIATE_CP_EDIT_ELLA_ACCOUNT_FLOW = '[contract-partners] INITIATE_CP_EDIT_ELLA_ACCOUNT_FLOW';
export const DECLINE_CP_ELLA_ACCOUNT_EDIT_FLOW = '[contract-partners] DECLINE_CP_ELLA_ACCOUNT_EDIT_FLOW';
export const CONFIRM_CP_ELLA_ACCOUNT_EDIT_FLOW = '[contract-partners] CONFIRM_CP_ELLA_ACCOUNT_EDIT_FLOW';
export const INITIATE_UPDATE_CONTRACT_PARTNER_BC_CONTACT_DATA = '[contract-partners] INITIATE_UPDATE_CONTRACT_PARTNER_BC_CONTACT_DATA';

/**
 * Event action types
 */
export const FETCH_CONTRACT_PARTNERS_REQUEST = '[contract-partners] FETCH_CONTRACT_PARTNERS_REQUEST';
export const FETCH_CONTRACT_PARTNERS_REQUEST_SENT = '[contract-partners] FETCH_CONTRACT_PARTNERS_REQUEST_SENT';
export const FETCH_CONTRACT_PARTNERS_REQUEST_SUCCEEDED = '[contract-partners] FETCH_CONTRACT_PARTNERS_REQUEST_SUCCEEDED';
export const FETCH_CONTRACT_PARTNERS_REQUEST_FAILED = '[contract-partners] FETCH_CONTRACT_PARTNERS_REQUEST_FAILED';

export const FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST = '[contract-partners] FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST';
export const FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST_SENT = '[contract-partners] FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST_SENT';
export const FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST_SUCCEEDED = '[contract-partners] FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST_SUCCEEDED';
export const FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST_FAILED = '[contract-partners] FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST_FAILED';

export const FILTER_CP_DISTANCE_RECOMMENDATIONS_REQUEST = '[contract-partners] FILTER_CP_DISTANCE_RECOMMENDATIONS_REQUEST';
export const FILTER_CP_DISTANCE_RECOMMENDATIONS_REQUEST_SENT = '[contract-partners] FILTER_CP_DISTANCE_RECOMMENDATIONS_REQUEST_SENT';
export const FILTER_CP_DISTANCE_RECOMMENDATIONS_REQUEST_SUCCEEDED = '[contract-partners] FILTER_CP_DISTANCE_RECOMMENDATIONS_REQUEST_SUCCEEDED';
export const FILTER_CP_DISTANCE_RECOMMENDATIONS_REQUEST_FAILED = '[contract-partners] FILTER_CP_DISTANCE_RECOMMENDATIONS_REQUEST_FAILED';

export const SEARCH_CONTRACT_PARTNERS_REQUEST = '[contract-partners] SEARCH_CONTRACT_PARTNERS_REQUEST';
export const SEARCH_CONTRACT_PARTNERS_REQUEST_SENT = '[contract-partners] SEARCH_CONTRACT_PARTNERS_REQUEST_SENT';
export const SEARCH_CONTRACT_PARTNERS_REQUEST_SUCCEEDED = '[contract-partners] SEARCH_CONTRACT_PARTNERS_REQUEST_SUCCEEDED';
export const SEARCH_CONTRACT_PARTNERS_REQUEST_FAILED = '[contract-partners] SEARCH_CONTRACT_PARTNERS_REQUEST_FAILED';

export const FILTER_CP_SERVICE_AREAS_REQUEST = '[contract-partners] FILTER_CP_SERVICE_AREAS_REQUEST';
export const FILTER_CP_SERVICE_AREAS_REQUEST_SENT = '[contract-partners] FILTER_CP_SERVICE_AREAS_REQUEST_SENT';
export const FILTER_CP_SERVICE_AREAS_REQUEST_SUCCEEDED = '[contract-partners] FILTER_CP_SERVICE_AREAS_REQUEST_SUCCEEDED';
export const FILTER_CP_SERVICE_AREAS_REQUEST_FAILED = '[contract-partners] FILTER_CP_SERVICE_AREAS_REQUEST_FAILED';

export const FILTER_CONTRACT_PARTNERS_LOCATIONS_REQUEST = '[contract-partners] FILTER_CONTRACT_PARTNERS_LOCATIONS_REQUEST';
export const FILTER_CONTRACT_PARTNERS_LOCATIONS_REQUEST_SENT = '[contract-partners] FILTER_CONTRACT_PARTNERS_LOCATIONS_REQUEST_SENT';
export const FILTER_CONTRACT_PARTNERS_LOCATIONS_REQUEST_SUCCEEDED = '[contract-partners] FILTER_CONTRACT_PARTNERS_LOCATIONS_REQUEST_SUCCEEDED';
export const FILTER_CONTRACT_PARTNERS_LOCATIONS_REQUEST_FAILED = '[contract-partners] FILTER_CONTRACT_PARTNERS_LOCATIONS_REQUEST_FAILED';

export const FILTER_CP_AFFILIATE_PARTNERS_LOCATIONS_REQUEST = '[contract-partners] FILTER_CP_AFFILIATE_PARTNERS_LOCATIONS_REQUEST';
export const FILTER_CP_AFFILIATE_PARTNERS_LOCATIONS_REQUEST_SENT = '[contract-partners] FILTER_CP_AFFILIATE_PARTNERS_LOCATIONS_REQUEST_SENT';
export const FILTER_CP_AFFILIATE_PARTNERS_LOCATIONS_REQUEST_SUCCEEDED = '[contract-partners] FILTER_CP_AFFILIATE_PARTNERS_LOCATIONS_REQUEST_SUCCEEDED';
export const FILTER_CP_AFFILIATE_PARTNERS_LOCATIONS_REQUEST_FAILED = '[contract-partners] FILTER_CP_AFFILIATE_PARTNERS_LOCATIONS_REQUEST_FAILED';

export const UPDATE_CONTRACT_PARTNER_STATUS_REQUEST = '[contract-partners] UPDATE_CONTRACT_PARTNER_STATUS_REQUEST';
export const UPDATE_CONTRACT_PARTNER_STATUS_REQUEST_SENT = '[contract-partners] UPDATE_CONTRACT_PARTNER_STATUS_REQUEST_SENT';
export const UPDATE_CONTRACT_PARTNER_STATUS_REQUEST_SUCCEEDED = '[contract-partners] UPDATE_CONTRACT_PARTNER_STATUS_REQUEST_SUCCEEDED';
export const UPDATE_CONTRACT_PARTNER_STATUS_REQUEST_FAILED = '[contract-partners] UPDATE_CONTRACT_PARTNER_STATUS_REQUEST_FAILED';

export const UPDATE_CONTRACT_PARTNER_EMERGENCY_CALL_DATA_REQUEST = '[contract-partners] UPDATE_CONTRACT_PARTNER_EMERGENCY_CALL_DATA_REQUEST';
export const UPDATE_CONTRACT_PARTNER_EMERGENCY_CALL_DATA_REQUEST_SENT = '[contract-partners] UPDATE_CONTRACT_PARTNER_EMERGENCY_CALL_DATA_REQUEST_SENT';
export const UPDATE_CONTRACT_PARTNER_EMERGENCY_CALL_DATA_REQUEST_SUCCEEDED = '[contract-partners] UPDATE_CONTRACT_PARTNER_EMERGENCY_CALL_DATA_REQUEST_SUCCEEDED';
export const UPDATE_CONTRACT_PARTNER_EMERGENCY_CALL_DATA_REQUEST_FAILED = '[contract-partners] UPDATE_CONTRACT_PARTNER_EMERGENCY_CALL_DATA_REQUEST_FAILED';

export const UPDATE_CP_CONTACT_DETAILS_REQUEST = '[contract-partners] UPDATE_CP_CONTACT_DETAILS_REQUEST';
export const UPDATE_CP_CONTACT_DETAILS_REQUEST_SENT = '[contract-partners] UPDATE_CP_CONTACT_DETAILS_REQUEST_SENT';
export const UPDATE_CP_CONTACT_DETAILS_REQUEST_SUCCEEDED = '[contract-partners] UPDATE_CP_CONTACT_DETAILS_REQUEST_SUCCEEDED';
export const UPDATE_CP_CONTACT_DETAILS_REQUEST_FAILED = '[contract-partners] UPDATE_CP_CONTACT_DETAILS_REQUEST_FAILED';

export const UPDATE_CONTRACT_PARTNER_ACCOUNT_MANAGER_REQUEST = '[contract-partners] UPDATE_CONTRACT_PARTNER_ACCOUNT_MANAGER_REQUEST';
export const UPDATE_CONTRACT_PARTNER_ACCOUNT_MANAGER_REQUEST_SENT = '[contract-partners] UPDATE_CONTRACT_PARTNER_ACCOUNT_MANAGER_REQUEST_SENT';
export const UPDATE_CONTRACT_PARTNER_ACCOUNT_MANAGER_REQUEST_SUCCEEDED = '[contract-partners] UPDATE_CONTRACT_PARTNER_ACCOUNT_MANAGER_REQUEST_SUCCEEDED';
export const UPDATE_CONTRACT_PARTNER_ACCOUNT_MANAGER_REQUEST_FAILED = '[contract-partners] UPDATE_CONTRACT_PARTNER_ACCOUNT_MANAGER_REQUEST_FAILED';

export const UPDATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST = '[contract-partners] UPDATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST';
export const UPDATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_SENT = '[contract-partners] UPDATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_SENT';
export const UPDATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_SUCCEEDED = '[contract-partners] UPDATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_SUCCEEDED';
export const UPDATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_FAILED = '[contract-partners] UPDATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_FAILED';

export const CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST = '[contract-partners] CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST';
export const CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_SENT = '[contract-partners] CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_SENT';
export const CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_SUCCEEDED = '[contract-partners] CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_SUCCEEDED';
export const CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_FAILED = '[contract-partners] CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_FAILED';

export const UPDATE_CONTRACT_PARTNER_ASSIGNMENT_CHANNELS_REQUEST = '[contract-partners] UPDATE_CONTRACT_PARTNER_ASSIGNMENT_CHANNELS_REQUEST';
export const UPDATE_CONTRACT_PARTNER_ASSIGNMENT_CHANNELS_REQUEST_SENT = '[contract-partners] UPDATE_CONTRACT_PARTNER_ASSIGNMENT_CHANNELS_REQUEST_SENT';
export const UPDATE_CONTRACT_PARTNER_ASSIGNMENT_CHANNELS_REQUEST_SUCCEEDED = '[contract-partners] UPDATE_CONTRACT_PARTNER_ASSIGNMENT_CHANNELS_REQUEST_SUCCEEDED';
export const UPDATE_CONTRACT_PARTNER_ASSIGNMENT_CHANNELS_REQUEST_FAILED = '[contract-partners] UPDATE_CONTRACT_PARTNER_ASSIGNMENT_CHANNELS_REQUEST_FAILED';

export const SEARCH_CONTRACT_PARTNER_BY_ATTRIBUTE_REQUEST = '[contract-partners] SEARCH_CONTRACT_PARTNER_BY_ATTRIBUTE_REQUEST';
export const SEARCH_CONTRACT_PARTNER_BY_ATTRIBUTE_REQUEST_SENT = '[contract-partners] SEARCH_CONTRACT_PARTNER_BY_ATTRIBUTE_REQUEST_SENT';
export const SEARCH_CONTRACT_PARTNER_BY_ATTRIBUTE_REQUEST_SUCCEEDED = '[contract-partners] SEARCH_CONTRACT_PARTNER_BY_ATTRIBUTE_REQUEST_SUCCEEDED';
export const SEARCH_CONTRACT_PARTNER_BY_ATTRIBUTE_REQUEST_FAILED = '[contract-partners] SEARCH_CONTRACT_PARTNER_BY_ATTRIBUTE_REQUEST_FAILED';

export const SEARCH_RANDOM_LOCATION_GEOLOCATION_REQUEST = '[contract-partners] SEARCH_RANDOM_LOCATION_GEOLOCATION_REQUEST';
export const SEARCH_RANDOM_LOCATION_GEOLOCATION_REQUEST_SENT = '[contract-partners] SEARCH_RANDOM_LOCATION_GEOLOCATION_REQUEST_SENT';
export const SEARCH_RANDOM_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED = '[contract-partners] SEARCH_RANDOM_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED';
export const SEARCH_RANDOM_LOCATION_GEOLOCATION_REQUEST_FAILED = '[contract-partners] SEARCH_RANDOM_LOCATION_GEOLOCATION_REQUEST_FAILED';

export const FETCH_CONTRACT_PARTNER_RELATIONS_REQUEST = '[contract-partners] FETCH_CONTRACT_PARTNER_RELATIONS_REQUEST';
export const FETCH_CONTRACT_PARTNER_RELATIONS_REQUEST_SENT = '[contract-partners] FETCH_CONTRACT_PARTNER_RELATIONS_REQUEST_SENT';
export const FETCH_CONTRACT_PARTNER_RELATIONS_REQUEST_SUCCEEDED = '[contract-partners] FETCH_CONTRACT_PARTNER_RELATIONS_REQUEST_SUCCEEDED';
export const FETCH_CONTRACT_PARTNER_RELATIONS_REQUEST_FAILED = '[contract-partners] FETCH_CONTRACT_PARTNER_RELATIONS_REQUEST_FAILED';

export const UPDATE_CONTRACT_PARTNER_RELATIONS_REQUEST = '[contract-partners] UPDATE_CONTRACT_PARTNER_RELATIONS_REQUEST';
export const UPDATE_CONTRACT_PARTNER_RELATIONS_REQUEST_SENT = '[contract-partners] UPDATE_CONTRACT_PARTNER_RELATIONS_REQUEST_SENT';
export const UPDATE_CONTRACT_PARTNER_RELATIONS_REQUEST_SUCCEEDED = '[contract-partners] UPDATE_CONTRACT_PARTNER_RELATIONS_REQUEST_SUCCEEDED';
export const UPDATE_CONTRACT_PARTNER_RELATIONS_REQUEST_FAILED = '[contract-partners] UPDATE_CONTRACT_PARTNER_RELATIONS_REQUEST_FAILED';

export const UPDATE_CONTRACT_PARTNER_BASIC_SERVICES_REQUEST = '[contract-partners] UPDATE_CONTRACT_PARTNER_BASIC_SERVICES_REQUEST';
export const UPDATE_CONTRACT_PARTNER_BASIC_SERVICES_REQUEST_SENT = '[contract-partners] UPDATE_CONTRACT_PARTNER_BASIC_SERVICES_REQUEST_SENT';
export const UPDATE_CONTRACT_PARTNER_BASIC_SERVICES_REQUEST_SUCCEEDED = '[contract-partners] UPDATE_CONTRACT_PARTNER_BASIC_SERVICES_REQUEST_SUCCEEDED';
export const UPDATE_CONTRACT_PARTNER_BASIC_SERVICES_REQUEST_FAILED = '[contract-partners] UPDATE_CONTRACT_PARTNER_BASIC_SERVICES_REQUEST_FAILED';

export const UPDATE_CONTRACT_PARTNER_ADDITIONAL_SERVICES_REQUEST = '[contract-partners] UPDATE_CONTRACT_PARTNER_ADDITIONAL_SERVICES_REQUEST';
export const UPDATE_CONTRACT_PARTNER_ADDITIONAL_SERVICES_REQUEST_SENT = '[contract-partners] UPDATE_CONTRACT_PARTNER_ADDITIONAL_SERVICES_REQUEST_SENT';
export const UPDATE_CONTRACT_PARTNER_ADDITIONAL_SERVICES_REQUEST_SUCCEEDED = '[contract-partners] UPDATE_CONTRACT_PARTNER_ADDITIONAL_SERVICES_REQUEST_SUCCEEDED';
export const UPDATE_CONTRACT_PARTNER_ADDITIONAL_SERVICES_REQUEST_FAILED = '[contract-partners] UPDATE_CONTRACT_PARTNER_ADDITIONAL_SERVICES_REQUEST_FAILED';

export const UPDATE_CONTRACT_PARTNER_SERVICES_REQUEST = '[contract-partners] UPDATE_CONTRACT_PARTNER_SERVICES_REQUEST';
export const UPDATE_CONTRACT_PARTNER_SERVICES_REQUEST_SENT = '[contract-partners] UPDATE_CONTRACT_PARTNER_SERVICES_REQUEST_SENT';
export const UPDATE_CONTRACT_PARTNER_SERVICES_REQUEST_SUCCEEDED = '[contract-partners] UPDATE_CONTRACT_PARTNER_SERVICES_REQUEST_SUCCEEDED';
export const UPDATE_CONTRACT_PARTNER_SERVICES_REQUEST_FAILED = '[contract-partners] UPDATE_CONTRACT_PARTNER_SERVICES_REQUEST_FAILED';

export const FETCH_ACE_COMMISSIONERS_REQUEST = '[contract-partners] FETCH_ACE_COMMISSIONERS_REQUEST';
export const FETCH_ACE_COMMISSIONERS_REQUEST_SENT = '[contract-partners] FETCH_ACE_COMMISSIONERS_REQUEST_SENT';
export const FETCH_ACE_COMMISSIONERS_REQUEST_SUCCEEDED = '[contract-partners] FETCH_ACE_COMMISSIONERS_REQUEST_SUCCEEDED';
export const FETCH_ACE_COMMISSIONERS_REQUEST_FAILED = '[contract-partners] FETCH_ACE_COMMISSIONERS_REQUEST_FAILED';

export const SEARCH_SERVICE_CASES_REQUEST = '[contract-partners] SEARCH_SERVICE_CASES_REQUEST';
export const SEARCH_SERVICE_CASES_REQUEST_SENT = '[contract-partners] SEARCH_SERVICE_CASES_REQUEST_SENT';
export const SEARCH_SERVICE_CASES_REQUEST_SUCCEEDED = '[contract-partners] SEARCH_SERVICE_CASES_REQUEST_SUCCEEDED';
export const SEARCH_SERVICE_CASES_REQUEST_FAILED = '[contract-partners] SEARCH_SERVICE_CASES_REQUEST_FAILED';

export const UPDATE_CONTRACT_PARTNER_EXTERNAL_COMMISSIONERS_REQUEST = '[contract-partners] UPDATE_CONTRACT_PARTNER_EXTERNAL_COMMISSIONERS_REQUEST';
export const UPDATE_CONTRACT_PARTNER_EXTERNAL_COMMISSIONERS_REQUEST_SENT = '[contract-partners] UPDATE_CONTRACT_PARTNER_EXTERNAL_COMMISSIONERS_REQUEST_SENT';
export const UPDATE_CONTRACT_PARTNER_EXTERNAL_COMMISSIONERS_REQUEST_SUCCEEDED = '[contract-partners] UPDATE_CONTRACT_PARTNER_EXTERNAL_COMMISSIONERS_REQUEST_SUCCEEDED';
export const UPDATE_CONTRACT_PARTNER_EXTERNAL_COMMISSIONERS_REQUEST_FAILED = '[contract-partners] UPDATE_CONTRACT_PARTNER_EXTERNAL_COMMISSIONERS_REQUEST_FAILED';

export const UPDATE_CONTRACT_PARTNER_ADDITIONAL_FEATURES_REQUEST = '[contract-partners] UPDATE_CONTRACT_PARTNER_ADDITIONAL_FEATURES_REQUEST';
export const UPDATE_CONTRACT_PARTNER_ADDITIONAL_FEATURES_REQUEST_SENT = '[contract-partners] UPDATE_CONTRACT_PARTNER_ADDITIONAL_FEATURES_REQUEST_SENT';
export const UPDATE_CONTRACT_PARTNER_ADDITIONAL_FEATURES_REQUEST_SUCCEEDED = '[contract-partners] UPDATE_CONTRACT_PARTNER_ADDITIONAL_FEATURES_REQUEST_SUCCEEDED';
export const UPDATE_CONTRACT_PARTNER_ADDITIONAL_FEATURES_REQUEST_FAILED = '[contract-partners] UPDATE_CONTRACT_PARTNER_ADDITIONAL_FEATURES_REQUEST_FAILED';

export const UPDATE_CONTRACT_PARTNER_ELLA_ACTIVE_REQUEST = '[contract-partners] UPDATE_CONTRACT_PARTNER_ELLA_ACTIVE_REQUEST';
export const UPDATE_CONTRACT_PARTNER_ELLA_ACTIVE_REQUEST_SENT = '[contract-partners] UPDATE_CONTRACT_PARTNER_ELLA_ACTIVE_REQUEST_SENT';
export const UPDATE_CONTRACT_PARTNER_ELLA_ACTIVE_REQUEST_SUCCEEDED = '[contract-partners] UPDATE_CONTRACT_PARTNER_ELLA_ACTIVE_REQUEST_SUCCEEDED';
export const UPDATE_CONTRACT_PARTNER_ELLA_ACTIVE_REQUEST_FAILED = '[contract-partners] UPDATE_CONTRACT_PARTNER_ELLA_ACTIVE_REQUEST_FAILED';

export const CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST = '[contract-partners] CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST';
export const CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST_SENT = '[contract-partners] CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST_SENT';
export const CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST_SUCCEEDED = '[contract-partners] CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST_SUCCEEDED';
export const CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST_FAILED = '[contract-partners] CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST_FAILED';

export const SEARCH_CP_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST = '[contract-partners] SEARCH_CP_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST';
export const SEARCH_CP_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_SENT = '[contract-partners] SEARCH_CP_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_SENT';
export const SEARCH_CP_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_SUCCEEDED = '[contract-partners] SEARCH_CP_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_SUCCEEDED';
export const SEARCH_CP_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_FAILED = '[contract-partners] SEARCH_CP_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_FAILED';

export const CREATE_CONTRACT_PARTNER_QUALIFICATION_REQUEST = '[contract-partners] CREATE_CONTRACT_PARTNER_QUALIFICATION_REQUEST';
export const CREATE_CONTRACT_PARTNER_QUALIFICATION_REQUEST_SENT = '[contract-partners] CREATE_CONTRACT_PARTNER_QUALIFICATION_REQUEST_SENT';
export const CREATE_CONTRACT_PARTNER_QUALIFICATION_REQUEST_SUCCEEDED = '[contract-partners] CREATE_CONTRACT_PARTNER_QUALIFICATION_REQUEST_SUCCEEDED';
export const CREATE_CONTRACT_PARTNER_QUALIFICATION_REQUEST_FAILED = '[contract-partners] CREATE_CONTRACT_PARTNER_QUALIFICATION_REQUEST_FAILED';

export const DELETE_CP_OPERATING_UNIT_REQUEST = '[contract-partners] DELETE_CP_OPERATING_UNIT_REQUEST';
export const DELETE_CP_OPERATING_UNIT_REQUEST_SENT = '[contract-partners] DELETE_CP_OPERATING_UNIT_REQUEST_SENT';
export const DELETE_CP_OPERATING_UNIT_REQUEST_SUCCEEDED = '[contract-partners] DELETE_CP_OPERATING_UNIT_REQUEST_SUCCEEDED';
export const DELETE_CP_OPERATING_UNIT_REQUEST_FAILED = '[contract-partners] DELETE_CP_OPERATING_UNIT_REQUEST_FAILED';

export const CREATE_CP_OPERATING_UNIT_REQUEST = '[contract-partners] CREATE_CP_OPERATING_UNIT_REQUEST';
export const CREATE_CP_OPERATING_UNIT_REQUEST_SENT = '[contract-partners] CREATE_CP_OPERATING_UNIT_REQUEST_SENT';
export const CREATE_CP_OPERATING_UNIT_REQUEST_SUCCEEDED = '[contract-partners] CREATE_CP_OPERATING_UNIT_REQUEST_SUCCEEDED';
export const CREATE_CP_OPERATING_UNIT_REQUEST_FAILED = '[contract-partners] CREATE_CP_OPERATING_UNIT_REQUEST_FAILED';

export const SEARCH_CP_OPERATING_UNIT_LOCATION_GEOLOCATION_REQUEST = '[contract-partners] SEARCH_CP_OPERATING_UNIT_LOCATION_GEOLOCATION_REQUEST';
export const SEARCH_CP_OPERATING_UNIT_LOCATION_GEOLOCATION_REQUEST_SENT = '[contract-partners] SEARCH_CP_OPERATING_UNIT_LOCATION_GEOLOCATION_REQUEST_SENT';
export const SEARCH_CP_OPERATING_UNIT_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED = '[contract-partners] SEARCH_CP_OPERATING_UNIT_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED';
export const SEARCH_CP_OPERATING_UNIT_LOCATION_GEOLOCATION_REQUEST_FAILED = '[contract-partners] SEARCH_CP_OPERATING_UNIT_LOCATION_GEOLOCATION_REQUEST_FAILED';

export const UPDATE_CP_OPERATING_UNIT_REQUEST = '[contract-partners] UPDATE_CP_OPERATING_UNIT_REQUEST';
export const UPDATE_CP_OPERATING_UNIT_REQUEST_SENT = '[contract-partners] UPDATE_CP_OPERATING_UNIT_REQUEST_SENT';
export const UPDATE_CP_OPERATING_UNIT_REQUEST_SUCCEEDED = '[contract-partners] UPDATE_CP_OPERATING_UNIT_REQUEST_SUCCEEDED';
export const UPDATE_CP_OPERATING_UNIT_REQUEST_FAILED = '[contract-partners] UPDATE_CP_OPERATING_UNIT_REQUEST_FAILED';

export const CREATE_CP_CONTACT_DATA_REQUEST = '[contract-partners] CREATE_CP_CONTACT_DATA_REQUEST';
export const CREATE_CP_CONTACT_DATA_REQUEST_SENT = '[contract-partners] CREATE_CP_CONTACT_DATA_REQUEST_SENT';
export const CREATE_CP_CONTACT_DATA_REQUEST_SUCCEEDED = '[contract-partners] CREATE_CP_CONTACT_DATA_REQUEST_SUCCEEDED';
export const CREATE_CP_CONTACT_DATA_REQUEST_FAILED = '[contract-partners] CREATE_CP_CONTACT_DATA_REQUEST_FAILED';

export const DELETE_CP_CONTACT_DATA_REQUEST = '[contract-partners] DELETE_CP_CONTACT_DATA_REQUEST';
export const DELETE_CP_CONTACT_DATA_REQUEST_SENT = '[contract-partners] DELETE_CP_CONTACT_DATA_REQUEST_SENT';
export const DELETE_CP_CONTACT_DATA_REQUEST_SUCCEEDED = '[contract-partners] DELETE_CP_CONTACT_DATA_REQUEST_SUCCEEDED';
export const DELETE_CP_CONTACT_DATA_REQUEST_FAILED = '[contract-partners] DELETE_CP_CONTACT_DATA_REQUEST_FAILED';

export const UPDATE_CP_CONTACT_DATA_REQUEST = '[contract-partners] UPDATE_CP_CONTACT_DATA_REQUEST';
export const UPDATE_CP_CONTACT_DATA_REQUEST_SENT = '[contract-partners] UPDATE_CP_CONTACT_DATA_REQUEST_SENT';
export const UPDATE_CP_CONTACT_DATA_REQUEST_SUCCEEDED = '[contract-partners] UPDATE_CP_CONTACT_DATA_REQUEST_SUCCEEDED';
export const UPDATE_CP_CONTACT_DATA_REQUEST_FAILED = '[contract-partners] UPDATE_CP_CONTACT_DATA_REQUEST_FAILED';

export const DOWNLOAD_CP_OVERVIEW_REQUEST = '[contract-partners] DOWNLOAD_CP_OVERVIEW_REQUEST';
export const DOWNLOAD_CP_OVERVIEW_REQUEST_SENT = '[contract-partners] DOWNLOAD_CP_OVERVIEW_REQUEST_SENT';
export const DOWNLOAD_CP_OVERVIEW_REQUEST_SUCCEEDED = '[contract-partners] DOWNLOAD_CP_OVERVIEW_REQUEST_SUCCEEDED';
export const DOWNLOAD_CP_OVERVIEW_REQUEST_FAILED = '[contract-partners] DOWNLOAD_CP_OVERVIEW_REQUEST_FAILED';

export const SEARCH_CP_LOCATION_GEOLOCATION_REQUEST = '[contract-partners] SEARCH_CP_LOCATION_GEOLOCATION_REQUEST';
export const SEARCH_CP_LOCATION_GEOLOCATION_REQUEST_SENT = '[contract-partners] SEARCH_CP_LOCATION_GEOLOCATION_REQUEST_SENT';
export const SEARCH_CP_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED = '[contract-partners] SEARCH_CP_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED';
export const SEARCH_CP_LOCATION_GEOLOCATION_REQUEST_FAILED = '[contract-partners] SEARCH_CP_LOCATION_GEOLOCATION_REQUEST_FAILED';

export const UPDATE_CP_LOCATION_REQUEST = '[contract-partners] UPDATE_CP_LOCATION_REQUEST';
export const UPDATE_CP_LOCATION_REQUEST_SENT = '[contract-partners] UPDATE_CP_LOCATION_REQUEST_SENT';
export const UPDATE_CP_LOCATION_REQUEST_SUCCEEDED = '[contract-partners] UPDATE_CP_LOCATION_REQUEST_SUCCEEDED';
export const UPDATE_CP_LOCATION_REQUEST_FAILED = '[contract-partners] UPDATE_CP_LOCATION_REQUEST_FAILED';

export const FETCH_CONTRACT_PARTNER_BC_DETAILS_REQUEST = '[contract-partners] FETCH_CONTRACT_PARTNER_BC_DETAILS_REQUEST';
export const FETCH_CONTRACT_PARTNER_BC_DETAILS_REQUEST_SENT = '[contract-partners] FETCH_CONTRACT_PARTNER_BC_DETAILS_REQUEST_SENT';
export const FETCH_CONTRACT_PARTNER_BC_DETAILS_REQUEST_SUCCEEDED = '[contract-partners] FETCH_CONTRACT_PARTNER_BC_DETAILS_REQUEST_SUCCEEDED';
export const FETCH_CONTRACT_PARTNER_BC_DETAILS_REQUEST_FAILED = '[contract-partners] FETCH_CONTRACT_PARTNER_BC_DETAILS_REQUEST_FAILED';

export const FETCH_CP_ACCOUNT_PARTY_DETAILS_REQUEST = '[contract-partners] FETCH_CP_ACCOUNT_PARTY_DETAILS_REQUEST';
export const FETCH_CP_ACCOUNT_PARTY_DETAILS_REQUEST_SENT = '[contract-partners] FETCH_CP_ACCOUNT_PARTY_DETAILS_REQUEST_SENT';
export const FETCH_CP_ACCOUNT_PARTY_DETAILS_REQUEST_SUCCEEDED = '[contract-partners] FETCH_CP_ACCOUNT_PARTY_DETAILS_REQUEST_SUCCEEDED';
export const FETCH_CP_ACCOUNT_PARTY_DETAILS_REQUEST_FAILED = '[contract-partners] FETCH_CP_ACCOUNT_PARTY_DETAILS_REQUEST_FAILED';

export const GET_BC_CONTACTS_SEARCH_RESULTS_REQUEST = '[contract-partners] GET_BC_CONTACTS_SEARCH_RESULTS_REQUEST';
export const GET_BC_CONTACTS_SEARCH_RESULTS_REQUEST_SENT = '[contract-partners] GET_BC_CONTACTS_SEARCH_RESULTS_REQUEST_SENT';
export const GET_BC_CONTACTS_SEARCH_RESULTS_REQUEST_SUCCEEDED = '[contract-partners] GET_BC_CONTACTS_SEARCH_RESULTS_REQUEST_SUCCEEDED';
export const GET_BC_CONTACTS_SEARCH_RESULTS_REQUEST_FAILED = '[contract-partners] GET_BC_CONTACTS_SEARCH_RESULTS_REQUEST_FAILED';

export const GET_BC_CREDITOR_REQUEST = '[contract-partners] GET_BC_CREDITOR_REQUEST';
export const GET_BC_CREDITOR_REQUEST_SENT = '[contract-partners] GET_BC_CREDITOR_REQUEST_SENT';
export const GET_BC_CREDITOR_REQUEST_SUCCEEDED = '[contract-partners] GET_BC_CREDITOR_REQUEST_SUCCEEDED';
export const GET_BC_CREDITOR_REQUEST_FAILED = '[contract-partners] GET_BC_CREDITOR_REQUEST_FAILED';

export const CREATE_NEW_CREDITOR_REQUEST = '[contract-partners] CREATE_NEW_CREDITOR_REQUEST';
export const CREATE_NEW_CREDITOR_REQUEST_SENT = '[contract-partners] CREATE_NEW_CREDITOR_REQUEST_SENT';
export const CREATE_NEW_CREDITOR_REQUEST_SUCCEEDED = '[contract-partners] CREATE_NEW_CREDITOR_REQUEST_SUCCEEDED';
export const CREATE_NEW_CREDITOR_REQUEST_FAILED = '[contract-partners] CREATE_NEW_CREDITOR_REQUEST_FAILED';

export const CREATE_NEW_CONTACT_REQUEST = '[contract-partners] CREATE_NEW_CONTACT_REQUEST';
export const CREATE_NEW_CONTACT_REQUEST_SENT = '[contract-partners] CREATE_NEW_CONTACT_REQUEST_SENT';
export const CREATE_NEW_CONTACT_REQUEST_SUCCEEDED = '[contract-partners] CREATE_NEW_CONTACT_REQUEST_SUCCEEDED';
export const CREATE_NEW_CONTACT_REQUEST_FAILED = '[contract-partners] CREATE_NEW_CONTACT_REQUEST_FAILED';

export const UPDATE_CREDITOR_REQUEST = '[contract-partners] UPDATE_CREDITOR_REQUEST';
export const UPDATE_CREDITOR_REQUEST_SENT = '[contract-partners] UPDATE_CREDITOR_REQUEST_SENT';
export const UPDATE_CREDITOR_REQUEST_SUCCEEDED = '[contract-partners] UPDATE_CREDITOR_REQUEST_SUCCEEDED';
export const UPDATE_CREDITOR_REQUEST_FAILED = '[contract-partners] UPDATE_CREDITOR_REQUEST_FAILED';

export const UPDATE_CONTACT_REQUEST = '[contract-partners] UPDATE_CONTACT_REQUEST';
export const UPDATE_CONTACT_REQUEST_SENT = '[contract-partners] UPDATE_CONTACT_REQUEST_SENT';
export const UPDATE_CONTACT_REQUEST_SUCCEEDED = '[contract-partners] UPDATE_CONTACT_REQUEST_SUCCEEDED';
export const UPDATE_CONTACT_REQUEST_FAILED = '[contract-partners] UPDATE_CONTACT_REQUEST_FAILED';

export const CREATE_CP_USERS_GROUP_REQUEST = '[contract-partners] CREATE_CP_USERS_GROUP_REQUEST';
export const CREATE_CP_USERS_GROUP_REQUEST_SENT = '[contract-partners] CREATE_CP_USERS_GROUP_REQUEST_SENT';
export const CREATE_CP_USERS_GROUP_REQUEST_SUCCEEDED = '[contract-partners] CREATE_CP_USERS_GROUP_REQUEST_SUCCEEDED';
export const CREATE_CP_USERS_GROUP_REQUEST_FAILED = '[contract-partners] CREATE_CP_USERS_GROUP_REQUEST_FAILED';

export const CREATE_ELLA_USER_REQUEST = '[contract-partners] CREATE_ELLA_USER_REQUEST';
export const CREATE_ELLA_USER_REQUEST_SENT = '[contract-partners] CREATE_ELLA_USER_REQUEST_SENT';
export const CREATE_ELLA_USER_REQUEST_SUCCEEDED = '[contract-partners] CREATE_ELLA_USER_REQUEST_SUCCEEDED';
export const CREATE_ELLA_USER_REQUEST_FAILED = '[contract-partners] CREATE_ELLA_USER_REQUEST_FAILED';

export const UPDATE_CONTRACT_PARTNER_ELLA_ACCOUNT_REQUEST = '[contract-partners] UPDATE_CONTRACT_PARTNER_ELLA_ACCOUNT_REQUEST';
export const UPDATE_CONTRACT_PARTNER_ELLA_ACCOUNT_REQUEST_SENT = '[contract-partners] UPDATE_CONTRACT_PARTNER_ELLA_ACCOUNT_REQUEST_SENT';
export const UPDATE_CONTRACT_PARTNER_ELLA_ACCOUNT_REQUEST_SUCCEEDED = '[contract-partners] UPDATE_CONTRACT_PARTNER_ELLA_ACCOUNT_REQUEST_SUCCEEDED';
export const UPDATE_CONTRACT_PARTNER_ELLA_ACCOUNT_REQUEST_FAILED = '[contract-partners] UPDATE_CONTRACT_PARTNER_ELLA_ACCOUNT_REQUEST_FAILED';

export const FETCH_CONTRACT_PARTNER_DEUM_USERS_REQUEST = '[contract-partners] FETCH_CONTRACT_PARTNER_DEUM_USERS_REQUEST';
export const FETCH_CONTRACT_PARTNER_DEUM_USERS_REQUEST_SENT = '[contract-partners] FETCH_CONTRACT_PARTNER_DEUM_USERS_REQUEST_SENT';
export const FETCH_CONTRACT_PARTNER_DEUM_USERS_REQUEST_SUCCEEDED = '[contract-partners] FETCH_CONTRACT_PARTNER_DEUM_USERS_REQUEST_SUCCEEDED';
export const FETCH_CONTRACT_PARTNER_DEUM_USERS_REQUEST_FAILED = '[contract-partners] FETCH_CONTRACT_PARTNER_DEUM_USERS_REQUEST_FAILED';

export const FETCH_AFFILIATED_CONTRACT_PARTNER_REQUEST = '[contract-partners] FETCH_AFFILIATED_CONTRACT_PARTNER_REQUEST';
export const FETCH_AFFILIATED_CONTRACT_PARTNER_REQUEST_SENT = '[contract-partners] FETCH_AFFILIATED_CONTRACT_PARTNER_REQUEST_SENT';
export const FETCH_AFFILIATED_CONTRACT_PARTNER_REQUEST_SUCCEEDED = '[contract-partners] FETCH_AFFILIATED_CONTRACT_PARTNER_REQUEST_SUCCEEDED';
export const FETCH_AFFILIATED_CONTRACT_PARTNER_REQUEST_FAILED = '[contract-partners] FETCH_AFFILIATED_CONTRACT_PARTNER_REQUEST_FAILED';

export const FETCH_CONTRACT_PARTNER_ELO_PATH_REQUEST = '[contract-partners] FETCH_CONTRACT_PARTNER_ELO_PATH_REQUEST';
export const FETCH_CONTRACT_PARTNER_ELO_PATH_REQUEST_SENT = '[contract-partners] FETCH_CONTRACT_PARTNER_ELO_PATH_REQUEST_SENT';
export const FETCH_CONTRACT_PARTNER_ELO_PATH_REQUEST_SUCCEEDED = '[contract-partners] FETCH_CONTRACT_PARTNER_ELO_PATH_REQUEST_SUCCEEDED';
export const FETCH_CONTRACT_PARTNER_ELO_PATH_REQUEST_FAILED = '[contract-partners] FETCH_CONTRACT_PARTNER_ELO_PATH_REQUEST_FAILED';

export const FETCH_CONTRACT_PARTNER_ACCOUNT_MANAGERS_REQUEST = '[contract-partners] FETCH_CONTRACT_PARTNER_ACCOUNT_MANAGERS_REQUEST';
export const FETCH_CONTRACT_PARTNER_ACCOUNT_MANAGERS_REQUEST_SENT = '[contract-partners] FETCH_CONTRACT_PARTNER_ACCOUNT_MANAGERS_REQUEST_SENT';
export const FETCH_CONTRACT_PARTNER_ACCOUNT_MANAGERS_REQUEST_SUCCEEDED = '[contract-partners] FETCH_CONTRACT_PARTNER_ACCOUNT_MANAGERS_REQUEST_SUCCEEDED';
export const FETCH_CONTRACT_PARTNER_ACCOUNT_MANAGERS_REQUEST_FAILED = '[contract-partners] FETCH_CONTRACT_PARTNER_ACCOUNT_MANAGERS_REQUEST_FAILED';
/**
 * Store action types
 */
export const STORE_CONTRACT_PARTNERS = '[contract-partners] STORE_CONTRACT_PARTNERS';
export const STORE_CONTRACT_PARTNER_RESTRICTIONS = '[contract-partners] STORE_CONTRACT_PARTNER_RESTRICTIONS';
export const SET_CONTRACT_PARTNER_RECOMMENDATIONS = '[contract-partners] SET_CONTRACT_PARTNER_RECOMMENDATIONS';
export const SET_CONTRACT_PARTNER_CANDIDATES = '[contract-partners] SET_CONTRACT_PARTNER_CANDIDATES';
export const SET_RANDOM_LOCATION_CANDIDATES = '[contract-partners] SET_RANDOM_LOCATION_CANDIDATES';
export const STORE_CONTRACT_PARTNER_SEARCH_RESULTS = '[contract-partners] STORE_CONTRACT_PARTNER_SEARCH_RESULTS';
export const SET_CONTRACT_PARTNERS_MAP_RELOAD_BUTTON_VISIBILITY = '[contract-partners] SET_CONTRACT_PARTNERS_MAP_RELOAD_BUTTON_VISIBILITY';
export const SET_CONTRACT_PARTNERS_MAP_RELOAD_BUTTON_STATE = '[contract-partners] SET_CONTRACT_PARTNERS_MAP_RELOAD_BUTTON_STATE';
export const SET_CONTRACT_PARTNERS_MAP_POLYGONS_ERROR = '[contract-partners] SET_CONTRACT_PARTNERS_MAP_POLYGONS_ERROR';
export const SET_SELECTED_CONTRACT_PARTNER = '[contract-partners] SET_SELECTED_CONTRACT_PARTNER';
export const STORE_CONTRACT_PARTNER_RELATIONS = '[contract-partners] STORE_CONTRACT_PARTNER_RELATIONS';
export const STORE_ACE_COMMISSIONERS = '[contract-partners] STORE_ACE_COMMISSIONERS';
export const STORE_SERVICE_CASE_SEARCH_RESULTS = '[contract-partners] STORE_SERVICE_CASE_SEARCH_RESULTS';
export const STORE_CONTRACT_PARTNER_QUALITY_REPORTS = '[contract-partners] STORE_CONTRACT_PARTNER_QUALITY_REPORTS';
export const STORE_CP_QUALITY_MANAGEMENT_FEEDBACKS_SEARCH_RESULTS = '[contract-partners] STORE_CP_QUALITY_MANAGEMENT_FEEDBACKS_SEARCH_RESULTS';
export const REMOVE_CP_OPERATING_UNIT = '[contract-partners] REMOVE_CP_OPERATING_UNIT';
export const STORE_CP_OPERATING_UNIT = '[contract-partners] STORE_CP_OPERATING_UNIT';
export const SET_CP_OPERATING_UNIT_LOCATION_CANDIDATES = '[contract-partners] SET_CP_OPERATING_UNIT_LOCATION_CANDIDATES';
export const SET_SELECTED_CONTRACT_PARTNER_OPERATION_AREA = '[contract-partners] SET_SELECTED_CONTRACT_PARTNER_OPERATION_AREA';
export const STORE_AFFILIATE_PARTNERS = '[contract-partners] STORE_AFFILIATE_PARTNERS';
export const STORE_CP_CONTACT_DATA = '[contract-partners] STORE_CP_CONTACT_DATA';
export const REMOVE_CP_CONTACT_DATA = '[contract-partners] REMOVE_CP_CONTACT_DATA';
export const SET_CP_LOCATION_RECOMMENDATIONS = '[contract-partners] SET_CP_LOCATION_RECOMMENDATIONS';
export const SET_ARE_CONTRACT_PARTNER_BC_DETAILS_LOADING = '[contract-partners] SET_ARE_CONTRACT_PARTNER_BC_DETAILS_LOADING';
export const STORE_CONTRACT_PARTNER_BC_DETAILS = '[contract-partners] STORE_CONTRACT_PARTNER_BC_DETAILS';
export const SET_IS_CONTRACT_PARTNER_BC_DETAILS_ERROR = '[contract-partners] SET_IS_CONTRACT_PARTNER_BC_DETAILS_ERROR';
export const STORE_BC_CONTACTS_SEARCH_ERROR = '[contract-partners] STORE_BC_CONTACTS_SEARCH_ERROR';
export const STORE_BC_CONTACTS_SEARCH_RESULTS = '[contract-partners] STORE_BC_CONTACTS_SEARCH_RESULTS';
export const STORE_CONTACTS = '[contract-partners] STORE_CONTACTS';
export const STORE_HAS_ELLA_ACCOUNT = '[contract-partners] STORE_HAS_ELLA_ACCOUNT';
export const STORE_CONTRACT_PARTNER_ELO_PATH = '[contract-partners] STORE_CONTRACT_PARTNER_ELO_PATH';
export const SET_CONTACTS_SEARCH_PERSISTENCE_STATE = '[contract-partners] SET_CONTACTS_SEARCH_PERSISTENCE_STATE';
export const STORE_CONTRACT_PARTNER_ACCOUNT_MANAGERS = '[contract-partners] STORE_CONTRACT_PARTNER_ACCOUNT_MANAGERS';
